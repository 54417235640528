<svelte:head>
<link rel="stylesheet" type="text/css" href="https://cdn.wpcc.io/lib/1.0.2/cookieconsent.min.css"/><script src="https://cdn.wpcc.io/lib/1.0.2/cookieconsent.min.js" defer></script><script>window.addEventListener("load", function(){window.wpcc.init({"border":"thin","corners":"small","colors":{"popup":{"background":"#f6f6f6","text":"#000000","border":"#555555"},"button":{"background":"#555555","text":"#ffffff"}}, "content":{"href":"/#/privacy"}})});</script>
</svelte:head>

<script>
import { Landing as Auth, Webnav, Button, Input } from '../../components'
import { push } from "svelte-spa-router";
import { getContext } from 'svelte'
import { setSession } from '../../services/session.service';
const services = getContext('services')

let state = {
	username : "",
	password : ""
}

const login = async()=>{
	setTimeout(()=>{

		services.session.setSession({
			id_token : "ewogICJub25jZSI6ICJNRFk0T1dNeE16Y3RZelZqTlMwMFpERTVMV1l5Wm1ZdE5HSmpOemhqTlRCbFl6STAiLAogICJ0aW1lc3RhbXAiOiAiMjAxNy0xMi0xM1QxNTozODozMi4wMDBaIiwKICAidXNlcl91dWlkIjogIjM3MTFlNGU3LWY1NzQtNGU3MC05N2FjLWUzMjA0MjJiZDBhOCIKfQ==",
			user : {
				uuid : "3711e4e7-f574-4e70-97ac-e320422bd0a8"
			}
		})

		/*services.session.setSession({
			id_token : "eyJub25jZSI6ICJNRFk0T1dNeE16Y3RZelZqTlMwMFpERTVMV1l5Wm1ZdE5HSmpOemhqTlRCbFl6STAiLCAidGltZXN0YW1wIjogIjIwMTctMTItMTNUMTU6Mzg6MzIuMDAwWiIsICJ1c2VyX3V1aWQiOiAiMWI0YjkwYTYtZmIwNS00ZTA4LTgwN2MtZWFlZjUwYTRiYzM5In0=",
			user : {
				uuid : "1b4b90a6-fb05-4e08-807c-eaef50a4bc39"
			}
		})*/
		push('/home')
	}, 3000)
}
    
</script>

<Auth>

	<section slot="nav">
		<img src="/img/logo_background.png" alt="fleeo logo">
	</section>

	<section slot="main">
		<figure>
			<img src="img/flio-login.png"  alt="Flio login graphic">
		</figure>
		<article>
			<Input type="text" label="Username" margin="0em 0em 2em 0em" bind:value={state.username}></Input>
			<Input type="password" label="Password" margin="0em 0em 2em 0em" bind:value={state.password}></Input>
			<Button rounded width="100%" height="4rem" style="margin-bottom:1em;" on:click={login}>LOGIN</Button>
            <a href="#/signup" on:click={()=>{ push('/signup') }}>No account? Sign Up Here
		</article>
  	</section>
      
</Auth>


<style>

	section[slot="nav"]{
			width:100%;
			display:flex;
			text-align:center;
			justify-content: center;
			align-content: center;
			align-items: center;
	}
	
	section[slot="main"]{
		display:flex;
		flex-direction: column;
		box-sizing: border-box;
		height: 100%;
		justify-content: space-around;
		align-items: center;
	}

	figure, article{
		width:100%;
		text-align: center;
	}

	figure{
		padding:1em;
		margin:none;
		box-sizing: border-box;
	}

	/* figure img{
		width:100%;
	} */

	article :global(button){
		font-size:1.5rem;
	}

	/* unvisited link */
	a:link {
		color: var(--flio-black);
	}

	/* visited link */
	a:visited {
		color: var(--flio-black);
	}

	/* mouse over link */
	a:hover {
		color: var(--flio-black);
	}

	/* selected link */
	a:active {
		color: var(--flio-black);
	}

		figure img{
			width:100%;
		}

	@media (min-width:801px) {
		section[slot="main"]{
			padding:8rem 12rem;
		}
	
		figure img{
			width:40%;
		}
	}

	@media (min-width:1400px) { 
		figure img{
			width:40%;
		}

		section[slot="main"]{
			padding:14rem;
		}
	}
</style>