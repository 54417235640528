<script>
import { push } from "svelte-spa-router";
import { formatDate } from './../../helpers/'
import { Avatar } from './../'


export let style = "";

export let session = true;



</script>

<style>

    .webnav-container{
        display:flex;
        justify-content: space-between;
        width:100%;
        height:100%;
        box-sizing: border-box;
        align-content: center;
        align-items: center;
    }

    .avatar-holder{
        border-radius:50%;
        width:2em;
        height:2em;
        overflow:hidden;
        box-sizing: border-box;
        margin-right:1em;
    }

    .avatar-holder img{
        width:100%;
        height:100%;
    }

    b{
        font-size:18px;
        cursor: pointer;
    }

    .user-holder{
        display:flex;
        width:auto;
        align-items: center;
        justify-content: center;
        justify-items: center;
    }

    @media (min-width:1281px) {
        .webnav-container{
            padding: 0em 8em;
        }
    }
</style>


    <div class="webnav-container" {style}>

        <img src="/img/logo_white.png" alt="fleeo logo" width="98px">

        {#if session}
        <Avatar picture="https://i.pravatar.cc/150?img=12" name="Username" direction="row" fontSize="1rem" color="#FFF"></Avatar>
        {:else}
        <p on:click={()=>{push('/login')}}>Access</p>
        {/if}


    </div>
