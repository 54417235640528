<script>
	export let backgroundColor = "#EEEEEE";
	export let foregroundColor = "#000000";
	export let width = "30%";
	export let height = "auto";
	export let style = "";
	export let rounded = true;
	export let borderRadius = "0rem";
	export let margin = "1em 0.8em 0em 0em";
	export let padding = "1rem 1.5rem";


	if(rounded){
		borderRadius = "2rem"
	}

	$: chipStyle = `
		--borderRadius:${borderRadius};
		--backgroundColor:${backgroundColor};
		--foregroundColor:${foregroundColor};
		--width:${width};
		--height:${height};
		--margin:${margin};
		--padding:${padding};
		${style}
	`
</script>

<div class="chip" on:click style={chipStyle}>
    <slot></slot>
</div>

<style>
	.chip{
		font-size:0.75em;
   		display: inline-block;
	 	padding:var(--padding);
		min-width:2rem;
		min-height:0.5rem;
		width: var(--width);
		height: var(--height);
		border-radius:var(--borderRadius);
		box-sizing: border-box;
		color:var(--foregroundColor);
		background-color:var(--backgroundColor);
		text-align:center;
		transition: all 0.2s;
		border: thin var(--backgroundColor) solid;
		font-weight:500;
		cursor:pointer;
		border-radius: var(--borderRadius);
		-webkit-tap-highlight-color:  transparent;
		margin:var(--margin);
	}

	.chip:hover{
		filter: brightness(0.95);
	}


/* @media all and (max-width:30em){
	.chip{
		display:block;
		margin: 0.2em auto;
	}
} */
</style>
