<script>
    import { mdiMagnify } from '@mdi/js';

    export let type = "text";
    export let id = "field";
    export let value;
    export let label = "";
    export let disabled;

    export let width = "auto";
    export let inputWidth = "100%";
    export let height = "auto";
    export let margin = "0em 0em 2em 0em";
    export let placeholder = ""
    export let align = "left"

    export let bold;

    export let backgroundColor = "#F2F2F2";
    export let foregroundColor = "#000000";
    export let placeholderColor = "#BABABA";

    export let style = "";
    
    let inputMarginTop;

    $: if(label.length){
        inputMarginTop = "1rem"
    }else{
        inputMarginTop = "0rem"
    }


    let computedStyle =    `
        --margin:${margin};
        --width:${width};
        --inputWidth:${inputWidth};
        --height:${height};
        --backgroundColor:${backgroundColor};
        --foregroundColor:${foregroundColor};
        --placholderColor:${placeholderColor};
        ${style}
    `
</script>

<style>
    .field-container{
        height:auto;
        width:var(--width);
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        justify-items: flex-start;
        /* padding:0.5em 1em; */
        margin: var(--margin);
        border-radius:0.25em;   
        box-sizing: border-box;
    }

    .field-container input{
        width:var(--inputWidth);
        /* margin-right:0.5em; */
        height:var(--height);
        padding:1.5em;
        border:none;
        background:var(--backgroundColor);
        color:var(--foregroundColor);
        text-align: var(--align);
        box-sizing: border-box;
    }

    .field-container input:focus{
        outline: none;
    }

    input{
        margin-top:var(--marginTop);
        text-align:var(--align);
        font-size:inherit;
        min-width: 8rem;
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: var(--placeholderColor);
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
     color: var(--placeholderColor);
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
      color: var(--placeholderColor);
    }

</style>

<label for={id} class="field-container" style={computedStyle}>
    {#if bold}
    <b>{label}</b>
    {:else}
    {label}
    {/if}
    {#if type == "password"}
        <input {disabled} type="password" {id} {placeholder} bind:value={value} style="--align:{align};--marginTop:{inputMarginTop}" />
    {:else if type == "number"}
        <input type="number" {disabled} {id} {placeholder} bind:value={value} style="--align:{align};--marginTop:{inputMarginTop}" />
    {:else}
        <input {disabled} {id} {placeholder} bind:value={value} style="--align:{align};--marginTop:{inputMarginTop}" />
    {/if}
</label>


