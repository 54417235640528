import API from '../../services/client'

export default async ()=>{
    const sessions = await API.CatalogueManager.sessions.findMany()


    for(let session in sessions.results){
        sessions.results[session].images = await API.CatalogueImage.images.findMany({ session_uuid : sessions.results[session].uuid })
    }
    
    return sessions
}