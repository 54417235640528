<script>
    export let hashtags = []
    export let action;
</script>

<div class="hashtags">
    {#each hashtags as tag, i}
    <span class="tag" on:click|stopPropagation={()=>{ if(action){ action(tag) } }}>{tag}</span>
    {/each}
</div>

<style>
    .hashtags{
        display:flex;
        flex-wrap:wrap;
    }

    .hashtags .tag{
        color: #2A50B2;
        cursor: pointer;
        font-size: 1rem;
        margin-right: 0.5rem;
    }
</style>