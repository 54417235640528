<script>
import { onMount } from 'svelte'
import { formatDate } from './../../helpers/'
import { push } from 'svelte-spa-router'
import Hashtags from './Hashtags.svelte'



export let live;


export let full = false;
export let style = "";

onMount(()=>{
    if(!(Array.isArray(live.images) && live.images.length)){
        live.cover = 'https://via.placeholder.com/150'
    }else{
        live.cover = live.images[0].href ?  live.images[0].href  : `https://www.fleeo.app/api/v1/images/${live.images[0].uri}`
    }
})

</script>

    <div class="live-container" class:full={full} {style} on:click={()=>{ push(`/live/${live.uuid}`) }}>
    {#if live}
        <div class="cover-container" style="background-image: url({live.cover})"></div>

          <div class="details-container">
          <div class="live-datetime">
          {formatDate(live.from, 'en-EN',
              { weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: false})}
          </div>
          <h2 class="live-title">{live.name}</h2>
            {#if live.session}
                <Hashtags hashtags={live.session.hashtags}></Hashtags>
            {/if}

            <!-- <p class="live-author">By {live.presenter_uuid}</p> -->
            {#if live.availability}
                <p class="live-availability"> Booked slots: {live.availability.booked_slots} / {live.availability.max_slots}</p>
            {/if}

          </div>

          {#if live.session}
            <p class="live-price">{live.session.tokens}</p>
          {/if}

        <!--div class="grid-container">
          <div class="day"> {formatDate(live.from, 'en-EN', { day: 'numeric'})}</div>
          <div class="month">{formatDate(live.from, 'en-EN', { month: 'short'})}</div>
        </div-->

    {/if}
    </div>


<style>
    .live-container{
        display: flex;
        justify-content: flex-start;
        align-content: center;
        align-items: center;
        padding: 1em;
        -webkit-box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.1);
        box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.1);
        border-radius: 0.2em;
        width:100%;
        box-sizing: border-box;
        cursor: pointer;
        transition: all ease-in 75ms;
    }

    .live-container:hover{
        -webkit-box-shadow: inset 1px 1px 3px rgba(0,0,0,0.1);
        box-shadow: inset 1px 1px 3px rgba(0,0,0,0.1);
        background:#ededef;
    }

     .full{
        width:100%!important;
    }

    .live-title{
        color: var(--flio-primary);
        font-size: 1.5rem;
        font-weight:500;
        margin: 0.5em 0em 0em 0em;
    }

    .live-availability{
        margin: 1em 0em 0.25rem 0em;
        font-weight:300;
        color: black;
        font-size:.8em;
    }

    .live-datetime{
        font-size:.9rem;
        margin: 0px;
        color:black;
        text-transform: uppercase;
    }

    .live-price{
        color: var(--flio-primary);
        font-size: 2.5rem;
        font-weight:800;
        display:flex;
        align-items: center;
        text-align: center;
        box-sizing: border-box;
    }

    .details-container{
        width:100%;
        padding: 1em;
    }

   .cover-container{
        min-width:8rem;
        min-height:8rem;
        width:8rem;
        height:8rem;
        border-radius:2%;
        background-position:center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    .live-author{
        margin: 0em 0em 1.5em 0em;
        font-size:.8em;
        color:var(--flio-light-gray);
        font-weight: 500;
    }

    .grid-container {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1.4fr 0.6fr;
      gap: 0px 0px;
      grid-template-areas:
        "day"
        "month";
      color: var(--flio-primary);

      background:#ededef;
      text-shadow:#fff 0 1px 0;
      -moz-border-radius:2px;
      -webkit-border-radius:2px;
      border-radius:3px;
      -moz-box-shadow:0 1px 1px #aaa;
      -webkit-box-shadow:0 1px 1px #aaa;
      box-shadow:0 1px 1px #aaa;
      padding: 1rem;
      text-align: center;

  }
  .day {
    grid-area: day;
    font-size: 1.8em;
  }
  .month {
    grid-area: month;
    font-size: 0.8em;
    text-transform: uppercase;
  }



@media (min-width:801px) {
     .full{
        width:100%!important;
    }
    .live-container{
        width:49%;
        padding:1em 1em;
        min-width: 320px;
    }


    .live-author{
        margin: 0em 0em 1.5em 0em;
        font-size:.8em;
        color:var(--flio-light-gray);
        font-weight: 500;
    }

}

@media (min-width:1400px) {
    .full{
        width:100%!important;
    }
    .live-container{
        width:calc(50% - 1em);
    }
    .live-container:nth-of-type(odd){
        margin-right:2em;
    }
}

</style>
