<script>
	export let rating = 3;
	export let stars = [1,2,3,4,5]
    export let disabled = false
</script>

  <div class="rate" class:disabled={disabled}>
		{#each  [...stars].reverse() as star, i}
    <input type="radio" id="star{star}" name="rate" value="{star}" checked={rating === star} on:click={()=>{ rating = star }} />
    <label for="star{star}" title="text">{star} stars</label>
		{/each}
    
  </div>
	
<style>
*{
    margin: 0;
    padding: 0;
}
.rate {
    float: left;
    height: 46px;
    padding: 0 10px;
}
.disabled{
    pointer-events:none;
}
.rate:not(:checked) > input {
    position:absolute;
    top:-9999px;
}
.rate:not(:checked) > label {
    float:right;
    width:1em;
    overflow:hidden;
    white-space:nowrap;
    cursor:pointer;
    font-size:30px;
    color:#ccc;
}
.rate:not(:checked) > label:before {
    content: '★ ';
}
.rate > input:checked ~ label {
    color: #ffc700;    
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
    color: #ffc700;  
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
    color: #ffc700;  
}

/* Modified from: https://github.com/mukulkant/Star-rating-using-pure-css */</style>


