import API from '../../services/client'

function removeEmpty(obj) {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null && v != ""));
  }

export default async (options = {})=>{

        let lives = await API.CatalogueManager.shows.findMany(removeEmpty({ language : 'en', ...options }))
        
        if(Array.isArray(lives.results) && lives.results.length){
            
            for(let live in lives.results){
                lives.results[live] = {
                    ...lives.results[live].live,
                    session : lives.results[live].session,
                    availability : lives.results[live].availability,
                    images : lives.results[live].images
                }

                if(live == (lives.results.length - 1)){
                    return lives
                }

            }
        }else{
            return lives
        }

}