import API from '../../services/client'

export default async ()=>{
    let bookings =  await API.CatalogueManager.shows.bookedShows()   
    if(Array.isArray(bookings.results) && bookings.results.length){
            
        for(let live in bookings.results){
            bookings.results[live] = {
                ...bookings.results[live].session,
                live : bookings.results[live].live,
                availability : bookings.results[live].availability
            }

            if(live == (bookings.results.length - 1)){
                return bookings
            }

        }
        
    }else{
        return bookings
    }
}