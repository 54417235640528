import API from './api.js'
import qs from 'qs';

        const findManyService = async (query)=>{
            const queryString = qs.stringify(query)
            return await API.get(`/shows?${queryString}`)
        }
            const bookedShowsService = async (query)=>{
            const queryString = qs.stringify(query)
            return await API.get(`/shows/booked?${queryString}`)
        }
    
export default  {
    findMany : findManyService,
    bookedShows : bookedShowsService
}

export const findMany = findManyService
export const bookedShows = bookedShowsService
