<script>
import { onMount } from 'svelte'
import { Search, Live, Chip, Skeleton  } from "../../components/";
import fetchLives from './fetchLives'

import { fly } from 'svelte/transition';

let state = {

}

let livesLoader

onMount(()=>{
    livesLoader = fetchLives()
})

const sessionStyle = "margin-bottom:2em;"

</script>

<!-- <Search></Search> -->

{#await livesLoader}
<div class="sessions-container">
    {#each [0,1,2,3] as skeleton, i}
    <Skeleton width="100%" height="8rem" margin="0em 0em 2em 0em"></Skeleton>
    {/each}
</div>
{:then lives}
{#if lives}
    <div class="sessions-container" transition:fly="{{ y: 100, duration: 500 }}">
        {#if lives.results && lives.results.length}
            {#each lives.results as live, i}
                <Live style="{sessionStyle}" avatar="{live.presenter_uuid}" {live}></Live>
            {/each}
        {/if}
    </div>
{/if}
{:catch error}
    An error ocurred while fetching the data
{/await}


<style>
.sessions-container{
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

</style>