<script>
	// Imports
	import { formatDate } from "../../helpers/"
	import { Module, Webnav, Aside, MobileTitle, MobileFooter, IconButton, FAB, Group, Button, Search } from "../../components/";
	import { getContext, onMount } from "svelte";
	import { push } from 'svelte-spa-router'

	import Sessions from './sessions.view.svelte'
	import Lives from './lives.view.svelte'

	import createSession from './createSession'

	// State
	export let params;
	let view;

	const handleCreateSession = async ()=>{
		const newSession = await createSession({
			name : "New session",
			category : "SOUL",
			description : "Description...",
			hashtags : ["#new", "#session"],
			language_iso : "en",
			tokens : 0
		})
		push(`/session/${newSession.session_uuid}`)
	}

	onMount(()=>{
		view = params.tab || "live"
	})



</script>

<style>
section[slot="header"]{
	font-size: 2em;
	font-weight:600;
	padding-top:1em;
}

section[slot="mobnav"]{
	height:100%;
}

section[slot="webnav"]{
	width: 100%;
}
</style>

<Module>

	<section slot="webnav">
		<Webnav></Webnav>
	</section>

	<section slot="mobnav">
		<MobileTitle title="Created by me">
			<IconButton size="3rem" backgroundColor="transparent" foregroundColor="black" icon="Bell"></IconButton>
		</MobileTitle>
	</section>

	<section slot="aside">
		<Aside></Aside>
	</section>
	
	<section slot="header">
		Created by me
	</section>

	<section slot="main" >
		<Group margin="0em 0em 2em 0em">
			<Button rounded width="100%" height="4rem" style="margin-right:1em;" backgroundColor="{view === 'live' ? 'var(--flio-primary)' : '#F0F0F0' }"  foregroundColor="{view === 'live' ? '#FFFFFF' : '#3F3D3D' }" on:click={()=>{ push('/created/live'); view = "live"  }}>LIVE</Button>
			<Button rounded width="100%" height="4rem" backgroundColor="{view === 'session' ? 'var(--flio-primary)' : '#F0F0F0' }"  foregroundColor="{view === 'session' ? '#FFFFFF' : '#3F3D3D' }"  on:click={()=>{ push('/created/session'); view = "session" }}>SESSION</Button>
		</Group>
		{#if view === 'session'}
			<Sessions></Sessions>
			<FAB on:click={handleCreateSession}></FAB>
		{:else}
			<Lives></Lives>
		{/if}
		
  	</section>

	<section slot="footer" >
		<MobileFooter></MobileFooter>		
  	</section>
</Module>


