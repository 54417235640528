<script>
export let layout = "row"; // row, grid, etc.
export let justifyContent = "flex-start";
export let alignItems = "stretch";  //justify-content
export let alignContent = "flex-start";
export let wrap = false;
export let backgroundColor = "transparent";
export let h = 'auto';
export let w = "auto";
export let margin = "0em";
export let border = "none";
export let bottomBorder = "none";
export let padding = "0em";
export let pointer = false;
export let scroll = 'visible';


let style;
$: style = `
--width:${w};
--height:${h};
--justifyContent:${justifyContent};
--alignContent:${alignContent};
--alignItems:${alignItems};
--backgroundColor:${backgroundColor};
--padding:${padding};
--margin:${margin};
--border:${border};
--bottomBorder:${bottomBorder};
--scroll:${scroll}
`

</script>

<style>
    .container{
        display: flex;
        height : var(--height);
        width : var(--width);
        justify-content : var(--justifyContent);
        align-content : var(--alignContent);
        align-items : var(--alignItems);
        background-color: var(--backgroundColor);
        margin: var(--margin);
        padding: var(--padding);
        border: var(--border);
        border-bottom: var(--bottomBorder);
        overflow: var(--scroll);
    }

    /* .container:hover{
        background: var(--hoverBackground);
        color: var(--hoverColor);
    } */

    .row{
        flex-flow: row nowrap;
    }

    .column{
        flex-flow: column nowrap;
    }

    .wrap{
        flex-wrap:wrap!important;
    }

    .container > *{
        width : var(--width);
    }

    .pointer{
        cursor:pointer;
    }
</style>

<div in class:wrap class="container" class:pointer class:row={layout=="row"} class:column={layout=="column"} {style} on:click>
    <slot></slot>
</div>