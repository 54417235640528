<script>
    export let title = "Title"
    export let align = "center"
    export let editable = false;
    export let full = false;
    import { pop } from 'svelte-spa-router'
    import { fetchIconPath } from './../../helpers/'


    let style = `
        --align:${align}
    `
</script>

<div class="title-container" {style}>
    <div>
        <span on:click={pop}>
        <img src={fetchIconPath('back')} alt="<" width="22rem">
        </span>
        <!--
        {#if editable}
        <input class:full={full} type="text" bind:value={title} />
        {:else}
        {title}
        {/if}
        -->
    </div>
    <div class="slot-container">
        <slot></slot>
    </div>
</div>

<style>
    .title-container{
        font-family: 'Poppins', sans-serif;
        font-size: 1.5rem;
	    font-weight:600;
        padding:2rem;
        display: flex;
        justify-content: space-between;
        align-items:var(--align);
    }

    input{
        font-family: 'Poppins', sans-serif;
        font-size: 1.5rem;
	    font-weight:600;
        padding:0rem 2rem;
        border:none;
        width:50%;
    }
    input.full{
        width:auto;
    }
    input:focus, input:active{
        outline:none;
    }
    span{
        margin-right:0.5rem;
        cursor:pointer;
    }
    .slot-container{
        cursor:pointer;
        -webkit-tap-highlight-color: transparent;
    }
</style>
