<script>
import { onMount } from 'svelte'
import { Search, Live, Chip, Skeleton  } from "../../components/";
import fetchLives from './fetchLives'
import fetchCategories from './fetchCategories'

import theme from '../../services/theme.service'


let state = {
    selectedCategory : null,
    searchQuery : null
}

let livesLoader, categoriesLoader;

onMount(async ()=>{
    categoriesLoader = fetchCategories({ setColor : false })
    livesLoader = fetchLives()
})

const search=async()=>{
    const options = { cat : state.selectedCategory, q : state.searchQuery }
    livesLoader = fetchLives(options)
}

const toggleCategory=async(category)=>{
    if(state.selectedCategory === category.code){
        state.selectedCategory = null
        theme.setPrimaryColor()
    }else{
        theme.setPrimaryColor(category.color)
        state.selectedCategory = category.code
    }
    search()
}

const sessionStyle = "margin-bottom:2em;"

</script>

<Search on:input={search} on:submit={search} bind:value={state.searchQuery}></Search>


{#await categoriesLoader}
<div class="chips-container">
    {#each [0,1,2] as skeleton, i}
    <Skeleton width="4rem" height="2rem" margin="0em 1em 0em 1em"></Skeleton>
    {/each}
</div>
{:then categories}
<div class="chips-container" >
    {#if categories && categories.length}
        {#each categories as category, i}
            <Chip on:click={()=>{ toggleCategory(category) }}
                  backgroundColor={ category.code === state.selectedCategory ? category.color : "#EEEEEE" }
                  foregroundColor={category.code === state.selectedCategory ? "white" : "black" }
                  padding="1rem 2rem">{category.value}
            </Chip>
        {/each}
    {:else}
        No categories found
    {/if}
</div>
{:catch error}
    An error ocurred while fetching categories
{/await}

{#await livesLoader}
<div class="sessions-container">
    {#each [0,1,2,3] as skeleton, i}
    <Skeleton width="100%" height="8rem" margin="0em 0em 2em 0em"></Skeleton>
    {/each}
</div>
{:then lives}

<div class="sessions-container" >
    {#if lives}
      {#if lives.results.length > 0}
        {#each lives.results as live, i}
          <Live style="{sessionStyle}" avatar="{live.presenter_uuid}" {live}></Live>
        {/each}
        {:else}
          No results found.  Try a different search or category.
      {/if}
    {/if}
</div>
{:catch error}
    An error ocurred while fetching the data
{/await}


<style>

.sessions-container{
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.chips-container{
    display:flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0em 0em 2em 0em;
    text-transform: capitalize;
}

</style>
