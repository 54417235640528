<script>
import { onMount } from 'svelte'
import { fly } from 'svelte/transition';
import { Search, Session, Skeleton  } from "../../components/";
import fetchFavorites from './fetchFavorites'

const sessionStyle = "margin-bottom:2em;"

let state = {
    
}

let favoritesLoader

onMount(async()=>{
   favoritesLoader = fetchFavorites()
})

</script>


<!-- <Search></Search> -->

{#await favoritesLoader}
<div class="sessions-container">
    {#each [0,1,2,3] as skeleton, i}
    <Skeleton width="100%" height="8rem" margin="0em 0em 2em 0em"></Skeleton>
    {/each}
</div>
{:then sessions}
{#if sessions}
    <div class="sessions-container" transition:fly="{{ y: 100, duration: 500 }}">
        {#if sessions && sessions.length}
            {#each sessions as session, i}
                <Session style="{sessionStyle}" {session}></Session>
            {/each}
        {:else}
            No favorite sessions
        {/if}
    </div>
{/if}
{:catch error}
    An error ocurred while fetching the favorite sessions
{/await}

<style>
.sessions-container{
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

</style>