<script>
import { onMount } from 'svelte'
import { formatDate } from './../../helpers/'
import { push } from 'svelte-spa-router'
import Hashtags from './Hashtags.svelte'

export let session;
export let showHashtags = true;
export let style = "";
export let full = false;


onMount(()=>{
    if(!(Array.isArray(session.images) && session.images.length)){
        session.cover = 'https://via.placeholder.com/150'
    }else{
        session.cover = session.images[0].href
    }
})

</script>

    <div class="session-container" class:full={full} {style} on:click={()=>{ push(`/session/${session.uuid}`) }}>
    {#if session }
        <div class="cover-container" style="background-image: url({session.cover})"></div>
        <div class="details-container">
                <h2 class="session-title">{session.name}</h2>
                <!-- <p class="session-author">By {live.presenter_uuid}</p> -->
                <!-- <p class="session-availability">{live.availability.booked_slots} Slots available out of {live.availability.max_slots}</p> -->
                <p class="session-datetime">{formatDate(session.created_at, 'en-EN')}</p>
                {#if showHashtags}
                    <Hashtags hashtags={session.hashtags}></Hashtags>
                {/if}
        </div>
        <div class="session-price">{session.tokens}</div>
    {/if}
    </div>



<style>
    .session-container{
        display: flex;
        justify-content: flex-start;
        align-content: center;
        align-items: center;
        padding: 1em;
        -webkit-box-shadow: 0px 0px 9px 3px rgba(0,0,0,0.1);
        box-shadow: 0px 0px 9px 3px rgba(0,0,0,0.1);
        border-radius: 1em;
        width:100%;
        box-sizing: border-box;
        cursor:pointer;
        transition: all ease-in 75ms;
    }

 .full{
        width:100%!important;
    }
    .session-container:hover{
        transform: scale(1.05);
    }

    .session-title{
        color: var(--flio-primary);
        font-size: 1.5rem;
        font-weight:500;
        margin: 0em 0em 0em 0em;
    }


    .session-availability{
        margin: 0em 0em 0.25rem 0em;
        font-weight:800;
        color:black;
        font-size:.8em;
    }

    .session-datetime{
        font-size:.8rem;
        margin:0px;
    }

    .session-price{
        color: var(--flio-primary);
        font-size: 2rem;
        font-weight:800;
        display:flex;
        align-items: center;
        text-align: center;
        box-sizing: border-box;
    }

    .details-container{
        width:100%;
        padding: 1em;
    }



    .cover-container{
        min-width:6rem;
        min-height:6rem;
        width:6rem;
        height:6rem;
        border-radius:50%;
        background-position:center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }


    .session-author{
        margin: 0em 0em 1.5em 0em;
        font-size:.8em;
        color:var(--flio-light-gray);
        font-weight: 500;
    }



@media (min-width:801px) {

     .full{
        width:100%!important;
    }
    .session-container{
        width:100%;
        padding:1em 2em;
        min-width: 320px;
    }



    .session-author{
        margin: 0em 0em 1.5em 0em;
        font-size:.8em;
        color:var(--flio-light-gray);
        font-weight: 500;
    }

}

@media (min-width:1400px) {
     .full{
        width:100%!important;
    }
    .session-container{
        width:calc(50% - 1em);
    }
    .session-container:nth-of-type(odd){
        margin-right:2em;
    }
}

</style>
