<script>
	// Imports
	import { push } from 'svelte-spa-router'
	import { formatDate } from "../../helpers/"
	import { Module as Page, Webnav, Aside, MobileTitle, MobileFooter, Button } from "../../components/";
	import { getContext, onMount } from "svelte";


</script>

<style>
section[slot="header"]{
	font-size: 2em;
	font-weight:600;
	padding-top:1em;
}

section[slot="mobnav"]{
	height:100%;
}

section[slot="webnav"]{
	width: 100%;
}

section[slot="main"] div{
	display:flex;
	flex-direction: column;
}

.col{
	display:flex;
	justify-content: center;
	margin-bottom:2em;
}


@media (min-width:801px) {
	section[slot="main"] div{
		display:grid;
		grid-template-rows: "1fr";
		grid-template-columns: "1fr 1fr 1fr";
		grid-template-areas: "col1 col2 col3"
	}

	.col{
		display:flex;
		justify-content: center;
	}

	.col-1{
		grid-area: col1;
	}

	.col-2{
		grid-area: col2;
	}

	.col-3{
		grid-area: col3;
	}
}
</style>

<Page>

	<section slot="webnav">
		<Webnav></Webnav>
	</section>

	<section slot="mobnav">
		<MobileTitle title="Account"></MobileTitle>
	</section>

	<section slot="aside">
		<Aside></Aside>
	</section>

	<section slot="header">
		Account
	</section>

	<section slot="main" >
		<div class="row">
			<span class="col col-1">
				<Button width="calc(100% - 1em)" height="4em" rounded on:click={()=>{ push('/streaming') }}>Streaming Settings</Button>
			</span>
			<span class="col col-2">
				<Button width="calc(100% - 1em)" height="4em" rounded on:click={()=>{ push('/profile') }}>Personal Data</Button>
			</span>
			<span class="col col-3">
				<Button width="calc(100% - 1em)" height="4em" rounded on:click={()=>{ push('/finance') }}>Token and Finance</Button>
			</span>
		</div>
		<img src="/img/account.png" alt="Account graphic">
  	</section>

</Page>
