import API from './api.js'
import qs from 'qs';

        const findManyService = async (query)=>{
            const queryString = qs.stringify(query)
            return await API.get(`/feedbacks?${queryString}`)
        }
            const createService = async (body)=>{
            return await API.post(`feedbacks`, body)
        }
            const removeService = async (feedback_uuid, body)=>{
            return await API.delete(`feedbacks/${ feedback_uuid }`, body)
        }

export default  {
    findMany : findManyService,
    create : createService,
    remove : removeService
}

export const findMany = findManyService
export const create = createService
export const remove = removeService
