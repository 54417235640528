<script>
import { onMount } from 'svelte'
import { fly } from 'svelte/transition';
import { Search, Session, Live, Skeleton  } from "../../components/";
import fetchBooked from './fetchBooked'


const sessionStyle = "margin-bottom:2em;"

let state = {
    sessions : []
}

let bookedLoader;

onMount(async()=>{
   bookedLoader = fetchBooked()
})

</script>

<!-- <Search></Search> -->

{#await bookedLoader}
<div class="sessions-container">
    {#each [0,1,2,3] as skeleton, i}
    <Skeleton width="100%" height="8rem" margin="0em 0em 2em 0em"></Skeleton>
    {/each}
</div>
{:then booked}
    {#if booked}
    <div class="sessions-container" transition:fly="{{ y: 100, duration: 500 }}">
        {#if booked.results && booked.results.length}
            {#each booked.results as session, i}
            <Live style="{sessionStyle}" live={session}></Live>
            {/each}
        {:else}
            No bookings found.
        {/if}
    </div>
    {/if}
{:catch error}
    An error ocurred while fetching the booked sessions
{/await}

<style>
.sessions-container{
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

</style>