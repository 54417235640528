let pathJoin = function(parts, sep){
   var separator = sep || '/';
   var replace   = new RegExp(separator+'{1,}', 'g');
   return parts.join(separator).replace(replace, separator);
}

//Bam, a basic alert
let fetchIconPath = function(name, scope="menu", quality="96w"){
    var nameToFile = new Map();
    nameToFile.set('home', 'search.png');
    nameToFile.set('booked', 'calendar-date.png');
    nameToFile.set('favorites', 'pin.png');
    nameToFile.set('created', 'pencil-1.png');
    nameToFile.set('settings', 'cog.png');
    nameToFile.set('help', 'question-help-message.png');
    nameToFile.set('account', 'app-window-user.png');
    nameToFile.set('sharing', 'share.png');
    nameToFile.set('back', 'arrow.png');

   const basePath = "/img/";

   return pathJoin([basePath,scope, name, quality, nameToFile.get(name)]);
}

export default fetchIconPath;
