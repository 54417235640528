import API from './api.js'
import qs from 'qs';

        const findManyService = async (query)=>{
            const queryString = qs.stringify(query)
            return await API.get(`/tokens?${queryString}`)
        }
            const detailsTokensService = async (query)=>{
            const queryString = qs.stringify(query)
            return await API.get(`/tokens/details?${queryString}`)
        }
            const transferTokensService = async (body)=>{
            return await API.post(`tokens`, body)
        }
            const saleTokensService = async (body)=>{
            return await API.post(`tokens`, body)
        }
            const estimateSaleTokensService = async (body)=>{
            return await API.post(`tokens`, body)
        }
    
export default  {
    findMany : findManyService,
    detailsTokens : detailsTokensService,
    transferTokens : transferTokensService,
    saleTokens : saleTokensService,
    estimateSaleTokens : estimateSaleTokensService
}

export const findMany = findManyService
export const detailsTokens = detailsTokensService
export const transferTokens = transferTokensService
export const saleTokens = saleTokensService
export const estimateSaleTokens = estimateSaleTokensService
