<script>
import { Landing as Auth, Webnav, Button, Input } from '../../components'

import { push } from "svelte-spa-router";
    
</script>

<Auth>

	<section slot="nav">
		<img src="/img/logo_background.png" alt="fleeo logo">
	</section>

	<section slot="main">
		<figure>
			<img src="img/flio-login.png"  alt="Flio login graphic">
		</figure>
		<article>
			<Input type="text" label="Username" margin="0em 0em 2em 0em"></Input>
			<Input type="password" label="Password" margin="0em 0em 2em 0em"></Input>
			<Button rounded width="100%" height="4em" style="margin-bottom:1em;" on:click={()=>{ push('/home') }}><b>SIGN UP</b></Button>
            <a href="#/login" on:click={()=>{ push('/login') }}>Already have an account? Sign In Here
		</article>
  	</section>
      
</Auth>


<style>

	section[slot="nav"]{
			width:100%;
			display:flex;
			text-align:center;
			justify-content: center;
			align-content: center;
			align-items: center;
	}
	
	section[slot="main"]{
		display:flex;
		flex-direction: column;
		box-sizing: border-box;
		height: 100%;
		justify-content: space-around;
		align-items: center;
	}

	figure, article{
		width:100%;
		text-align: center;
	}

	figure{
		padding:1em;
		margin:none;
		box-sizing: border-box;
	}

	/* figure img{
		width:100%;
	} */

	article :global(button){
		font-size:1.5rem;
	}

	/* unvisited link */
	a:link {
		color: var(--flio-black);
	}

	/* visited link */
	a:visited {
		color: var(--flio-black);
	}

	/* mouse over link */
	a:hover {
		color: var(--flio-black);
	}

	/* selected link */
	a:active {
		color: var(--flio-black);
	}

		figure img{
			width:100%;
		}

	@media (min-width:801px) {
		section[slot="main"]{
			padding:8rem 12rem;
		}
	
		figure img{
			width:40%;
		}
	}

	@media (min-width:1400px) { 
		figure img{
			width:40%;
		}

		section[slot="main"]{
			padding:14rem;
		}
	}
</style>