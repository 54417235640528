<script>
	// Imports
	import { formatDate } from "../../helpers/"
	import { push } from 'svelte-spa-router'
	import { Module, Webnav, Aside, MobileTitle, MobileFooter, Button } from "../../components/";

	import Sessions from './session.view.svelte'

	let state = {}



</script>

<Module>

	<section slot="webnav">
		<Webnav></Webnav>
	</section>


	<section slot="mobnav">
		{#if state.session}
		<MobileTitle editable={state.isOwner} bind:title="{state.session.name}" align="flex-start">

		</MobileTitle>
		{/if}
	</section>

	<section slot="aside">
		<Aside></Aside>
	</section>

	<section slot="header">
		Session
	</section>

	<section slot="main" >
		<Sessions bind:state></Sessions>
  	</section>

	<section slot="footer" >
		<MobileFooter></MobileFooter>
  	</section>
</Module>


<style>
section[slot="header"]{
	font-size: 2em;
	font-weight:600;
	padding-top:1em;
}

section[slot="mobnav"]{
	height:100%;
}

section[slot="webnav"]{
	width: 100%;
}

.title-button{
	position:relative;
	bottom:1rem;
}

</style>
