<script>
	// Imports
	import { formatDate } from "../../helpers/"
	import { Page, Webnav, Aside, MobileTitle, MobileFooter, Select, Button } from "../../components/";
	import { getContext, onMount } from "svelte";

	// State
	const { client, session} = getContext("services")
	let records = []
	let engine;
	
	// Fetch 
	const fetchRecords = () => { 
	
	}

</script>

<style>
section[slot="header"]{
	font-size: 2em;
	font-weight:600;
	padding-top:1em;
}

section[slot="mobnav"]{
	height:100%;
}

section[slot="webnav"]{
	width: 100%;
}

section[slot="main"]{
	height:100%;
}

span{
	width:100%;
	height:calc(100vh - 20rem);
	display:flex;
	flex-direction: column;
	justify-content: space-between;
	align-items:center;
}

@media (min-width:801px) {	
	span{
		width:32em;
		justify-content: flex-start;
		align-items:flex-start;
	}

}
</style>

<Page>

	<section slot="webnav">
		<Webnav></Webnav>
	</section>

	<section slot="mobnav">
		<MobileTitle title="Streaming Settings"></MobileTitle>
	</section>

	<section slot="aside">
		<Aside></Aside>
	</section>
	
	<section slot="header">
		Streaming Settings
	</section>

	<section slot="main" >
		<span>
		<Select width="100%" bind:value={engine} label="Select your platform">
			<option selected value="meet">Meet</option>
			<option value="zoom">Zoom</option>
		</Select>
		<Button rounded width="18rem">Save</Button>
		</span>
  	</section>
</Page>