import API from './api.js'
import qs from 'qs';

        const findManyService = async (query)=>{
            const queryString = qs.stringify(query)
            return await API.get(`/lives?${queryString}`)
        }
            const createService = async (body)=>{
            return await API.post(`lives`, body)
        }
            const findOneService = async (live_uuid, query)=>{
            const queryString = qs.stringify(query)
            return await API.get(`lives/${ live_uuid }?${queryString}`)
        }
        const updateService = async (live_uuid, body)=>{
            return await API.put(`lives/${ live_uuid }`, body)
        }
        const removeService = async (live_uuid, body)=>{
            return await API.delete(`lives/${ live_uuid }`, body)
        }

export default  {
    findMany : findManyService,
    create : createService,
    findOne : findOneService,
    update : updateService,
    remove : removeService
}

export const findMany = findManyService
export const create = createService
export const findOne = findOneService
export const update = updateService
export const remove = removeService
