import API from './api.js'
import qs from 'qs';

        const findManyService = async (query)=>{
            const queryString = qs.stringify(query)
            return await API.get(`/settings?${queryString}`)
        }
            const updateService = async (body)=>{
            return await API.put(`settings`, body)
        }
    
export default  {
    findMany : findManyService,
    update : updateService
}

export const findMany = findManyService
export const update = updateService
