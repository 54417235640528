<script>
    import Rating from './../atoms/Rating.svelte'
    export let feedback;
    export let disabled = true;
</script>

<div class=" ">
    <div class="emotion" style="background-image: url(/img/emoji/{feedback.emotion_code}.svg)">
    </div>
    <div class="feedback-content">
        <Rating rating={feedback.score} {disabled}></Rating>
        <p>{feedback.comment}<p>
    </div>
</div>

<style>

.feedback-container{
	display:flex;
	margin-bottom:1rem;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
}

.feedback-content {
	margin-left:1rem;
}

.feedback-content p{
	margin-top:0px;
	font-size:1rem;
    margin-left:1rem;
    font-weight:600;
}

.emotion{
    width:4rem;
    height:4rem;
}

</style>
