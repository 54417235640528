import API from '../../services/client'

export default async ()=>{

    let favorites = await API.Favorite.favorites.findMany()
    let sessions = []

    for(let eachFavorite in favorites){
        try{
            let favorite = favorites[eachFavorite]
            const session = await API.CatalogueManager.sessions.findOne(favorite.session_uuid)
            sessions.push(session)
            if(eachFavorite == favorites.length - 1){
                return sessions
            }
        }catch(err){
            throw new Error(err)
        }
    }

 }