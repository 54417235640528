import API from './api.js'
import qs from 'qs';

        const findManyService = async (query)=>{
            const queryString = qs.stringify(query)
            return await API.get(`/bookings?${queryString}`)
        }
            const createService = async (body)=>{
            return await API.post(`bookings`, body)
        }
            const findOneService = async (booking_uuid, query)=>{
            const queryString = qs.stringify(query)
            return await API.get(`bookings/${ booking_uuid }?${queryString}`)
        }
        const removeService = async (booking_uuid, body)=>{
            return await API.delete(`bookings/${ booking_uuid }`, body)
        }

export default  {
    findMany : findManyService,
    create : createService,
    findOne : findOneService,
    remove : removeService
}

export const findMany = findManyService
export const create = createService
export const findOne = findOneService
export const remove = removeService
