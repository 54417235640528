<script>
	// Imports
	import { formatDate } from "../../helpers/"
	import { Page, Webnav, Aside, MobileTitle, MobileFooter, Input, Button, Toggle } from "../../components/";
	import { getContext, onMount } from "svelte";

	// State
	const { client, session} = getContext("services")
	let records = []
	let engine;

	// Fetch
	const fetchRecords = () => {

	}

</script>

<style>
section[slot="header"]{
	font-size: 2em;
	font-weight:600;
	padding-top:1em;
}

section[slot="mobnav"]{
	height:100%;
}

section[slot="webnav"]{
	width: 100%;
}

section[slot="main"]{
	display:flex;
	flex-direction: column;
	justify-content: space-between;
	align-items:center;
	height:calc(100vh - 18rem);
}



.row{
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: space-between;
	width:24em;
	height: 4em;
}

.row p{
	width:10rem;
	margin-right:1rem;
}

.row p.dash{
	width:4rem;
	color:#CBCBCB;
	font-size:2rem;
	margin-right:2.5rem;
}

.row span{
	width:calc(100% - 16rem);
}

img{
	position:fixed;
	bottom:10em;
	left:-4em;
	z-index:-1;
	width:32rem;
}

.save{
	width:18rem;
	margin-top:2em;
}

.form{
	width:20rem;
	align-self:flex-start;
}

@media (min-width:801px) {
	section[slot="main"]{
		justify-content: flex-start;
		align-items:flex-start;
	}
	img{
		position:fixed;
		left: 50%;
		right:8rem;
		bottom:8rem;
		width:36rem;
	}
	.form{
		width:20rem;
	}
.save{
	width:20rem;
	margin-top:2em;
}


}
</style>

<Page>

	<section slot="webnav">
		<Webnav></Webnav>
	</section>

	<section slot="mobnav">
		<MobileTitle title="Personal Data"></MobileTitle>
	</section>

	<section slot="aside">
		<Aside></Aside>
	</section>

	<section slot="header">
		Personal Data
	</section>

	<section slot="main" >
		<div class="form">
			<div class="row">
				<p><b>Name</b></p>
				<p class="dash">-</p>
				<span><Input style="margin:0em;" value="Erick" backgroundColor="white" foregroundColor="black"></Input></span>
			</div>
			<div class="row">
				<p><b>Surname</b></p>
				<p class="dash">-</p>
				<span><Input style="margin:0em;" value="Erick" backgroundColor="white" foregroundColor="black"></Input></span>
			</div>
			<div class="row">
				<p><b>Email</b></p>
				<p class="dash">-</p>
				<span><Input style="margin:0em;" value="Erick" backgroundColor="white" foregroundColor="black"></Input></span>
			</div>
			<div class="row">
				<p><b>Username</b></p>
				<p class="dash">-</p>
				<span><Input style="margin:0em;" value="Erick" backgroundColor="white" foregroundColor="black"></Input></span>
			</div>
			<div class="row">
				<p><b>Password</b></p>
				<p class="dash">-</p>
				<span><Input type="password" style="margin:0em;" value="Erick" backgroundColor="white" foregroundColor="black"></Input></span>
			</div>
			<div class="row">
				<p><b>Visibility</b></p>
				<p class="dash">-</p>
				<span><Toggle checked></Toggle></span>
			</div>
		</div>
		<img src="/img/profile.png" alt="Profile graphic">
		<span class="save">
			<Button rounded width="100%">Save</Button>
		</span>
  	</section>
</Page>
