import API from './api.js'
import qs from 'qs';

        const findManyService = async (query)=>{
            const queryString = qs.stringify(query)
            return await API.get(`/sessions?${queryString}`)
        }
            const createService = async (body)=>{
            return await API.post(`sessions`, body)
        }
            const findOneService = async (session_uuid, query)=>{
            const queryString = qs.stringify(query)
            return await API.get(`sessions/${ session_uuid }?${queryString}`)
        }
        const updateService = async (session_uuid, body)=>{
            return await API.put(`sessions/${ session_uuid }`, body)
        }
        const removeService = async (session_uuid, body)=>{
            return await API.delete(`sessions/${ session_uuid }`, body)
        }
        const livesService = async (session_uuid, query)=>{
            const queryString = qs.stringify(query)
            return await API.get(`sessions/${ session_uuid }?${queryString}`)
        }

export default  {
    findMany : findManyService,
    create : createService,
    findOne : findOneService,
    update : updateService,
    remove : removeService,
    lives : livesService
}

export const findMany = findManyService
export const create = createService
export const findOne = findOneService
export const update = updateService
export const remove = removeService
export const lives = livesService
