import formatDateHelper from "./formatDate"
import getHashtagsHelper from "./getHashtags"
import isOddHelper from "./isOdd"
import fetchIconPathHelper from "./fetchIconPath"

export default {
    formatDate : formatDateHelper,
    isOdd : isOddHelper,
    getHashtags : getHashtagsHelper,
    fetchIconPath: fetchIconPathHelper

}

export const formatDate = formatDateHelper
export const isOdd = isOddHelper
export const getHashtags = getHashtagsHelper
export const fetchIconPath = fetchIconPathHelper
