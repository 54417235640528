<script>

export let src;
export let style;
export let width = "240px";
export let height = "320px";
export let margin = "0em 0em 0em 0em";
export let upload = false;


let computedStyle = `
    border-radius:1em;
    ${upload ? "background-image:url(/img/upload.png);" : `background-image:url(${src});`}
    ${upload ? "cursor:pointer;" : ""}
    ${style};
    width:${width};
    height:${height};
    --margin:${margin}
`;


</script>

<div class="image-container"  style={`${computedStyle};`}></div>

<style>
    .image-container{
        /* min-width:240px; */
        overflow:hidden;
        margin:var(--margin)!important;
         background-position:center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }


@media (min-width:801px) {
    .image-container{
        min-width:240px;
        overflow:hidden;
        margin:var(--margin)!important;
    }

    img{
        top:0px;
        left:0px;
        width:100%;
    }
}

</style>