import API from './api.js'
import qs from 'qs';

        const findManyService = async (query)=>{
            const queryString = qs.stringify(query)
            return await API.get(`/categories?${queryString}`)
        }
    
export default  {
    findMany : findManyService
}

export const findMany = findManyService
