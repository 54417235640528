<script>

  // Get context from app initialization or set default context values
  export let env;

  // get application index
  import app from "./index"

  // Set root context application
  import { setContext } from "svelte"

  // set env
  setContext("env", env)

  // set services
  setContext("services", app.services)

  // set helpers
  setContext("helpers", app.helpers)

  // config requests
  const { client } = app.services
  // client.instance.defaults.baseURL = env.apiURL
  client.instance.interceptors.response.use(app.interceptors.parseResponse)
  client.instance.interceptors.request.use(app.interceptors.authorizeRequest)

  // Load router and routes
  import Router from "svelte-spa-router";
  import { location, push } from "svelte-spa-router";
  import routes from "./routes/index";

  function conditionsFailed(event) {
    if(event.detail.location != '/login'){
      push('/login')
    }else{
      push('/home')
    }
  }


</script>


<Router {routes} on:conditionsFailed={conditionsFailed} />
