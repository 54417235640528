import authGuard from "./auth.guard";
import { pages } from "./../index";
import { wrap } from 'svelte-spa-router/wrap'

const routes = {
  // Unguarded routes
  // '/' : modules.PublicModule.Landing, // prod
  '/' : pages.Home,
  '/login' : pages.Login,
  '/signup' : pages.Signup,
  '/privacy' : pages.Privacy,
  '/home' : wrap({
    component : pages.Home,
    conditions : [authGuard]
  }),
  '/sessions' : wrap({
    component : pages.Booked,
    conditions : [authGuard]
  }),
  '/booked' : wrap({
    component : pages.Booked,
    conditions : [authGuard]
  }),
  '/favorites' : wrap({
    component : pages.Favorites,
    conditions : [authGuard]
  }),
  '/created/:tab?' : wrap({
    component : pages.Created,
    conditions : [authGuard]
  }),
  '/settings' : wrap({
    component : pages.Settings,
    conditions : [authGuard]
  }),
  '/profile' : wrap({
    component : pages.Profile,
    conditions : [authGuard]
  }),
  '/help' : wrap({
    component : pages.Help,
    conditions : [authGuard]
  }),
  '/finance/:status?' : wrap({
    component : pages.Finance,
    conditions : [authGuard]
  }),
  '/live/:uuid' : wrap({
    component : pages.Live,
    conditions : [authGuard]
  }),
  '/session/:uuid' : wrap({
    component : pages.Session,
    conditions : [authGuard]
  }),
  '/streaming' : wrap({
    component : pages.Streaming,
    conditions : [authGuard]
  }),
  '/account' : wrap({
    component : pages.Account,
    conditions : [authGuard]
  })

};

export default routes;
