import API from './api.js'
import qs from 'qs';

        const findManyService = async (query)=>{
            const queryString = qs.stringify(query)
            return await API.get(`/images?${queryString}`)
        }
            const createService = async (body)=>{
            return await API.post(`images`, body)
        }
            const downloadService = async (session_uuid,image_uuid_filename, query)=>{
            const params = `${ session_uuid }/${ image_uuid_filename }`
            const queryString = qs.stringify(query)
            return await API.get(`images/${ params }?${queryString}`)
        }
        const removeService = async (image_uuid, body)=>{
            return await API.delete(`images/${ image_uuid }`, body)
        }

export default  {
    findMany : findManyService,
    create : createService,
    download : downloadService,
    remove : removeService
}

export const findMany = findManyService
export const create = createService
export const download = downloadService
export const remove = removeService
