<script>
import { push } from "svelte-spa-router";
import { formatDate } from './../../helpers/'
import { Avatar, IconButton, Chip } from './../../components'


export let user = {
    picture : "https://i.pravatar.cc/150?img=12"
}

export let style = "";

export let session = true;



</script>




    <div class="mobilenav-container" {style}>

        <span class="logo-holder">
            <img src="img/logo.png" alt="FLEEO" width="120rem">
        </span>

        <nav>
            <!-- Avatar picture={user.picture} name="Account" on:click={()=>{push('/account')}}></Avatar -->
            <IconButton label="" icon="account" on:click={()=>{push('/account')}}></IconButton>
            <IconButton label="" icon="settings" on:click={()=>{push('/settings')}}></IconButton>
            <IconButton label="" icon="sharing" on:click={()=>{ location.href = "https://twitter.com/intent/tweet?text=Hey%20there,%20I'm%20using%20https%3A//www.fleeo.app.%20%20Join%20me!" }}></IconButton>
        </nav>


    </div>



<style>

    .mobilenav-container{
        display:grid;
        width:100%;
        height:100%;
        padding:0em 1em;
        box-sizing: border-box;
        grid-template-rows: 1fr;
        grid-template-columns: 40% 1fr;
        grid-template-areas: "logo nav";
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }



    .logo-holder{
        grid-area:logo;
        padding:0em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .logo-holder img{
        /* height: 4em; */
        /* margin-top:10% */
    }

    nav{
        grid-area:nav;
        display:flex;
        box-sizing: border-box;
        justify-content: flex-end;
        align-items: center;
        align-content: center;
        padding:0.5em 1em;
    }

</style>
