<script>
	export let code = null;
  let hover = false;
</script>

{#if hover}
<div class="reactions" on:mouseleave={()=>{ hover = false}} >
  <div class="reaction like" on:click={()=>{ code = 'like'; hover = false}} class:selected={code === 'like'}>
    <img src="/img/emoji/like.svg" alt="like emoji">
  </div>
  <div class="reaction love"  on:click={()=>{ code = 'love'; hover = false }} class:selected={code === 'love'}>
    <img src="/img/emoji/love.svg" alt="love emoji">
  </div>
  <div class="reaction fun" on:click={()=>{ code = 'fun'; hover = false }} class:selected={code === 'fun'}>
    <img src="/img/emoji/fun.svg" alt="fun emoji">
  </div>
  <div class="reaction wow"  on:click={()=>{ code = 'wow'; hover = false }} class:selected={code === 'wow'}>
    <img src="/img/emoji/wow.svg" alt="wow emoji">
  </div>
  <div class="reaction boring"  on:click={()=>{ code = 'boring'; hover = false }} class:selected={code === 'boring'}>
    <img src="/img/emoji/boring.svg" alt="boring emoji">
  </div>
  <div class="reaction angry" on:click={()=>{ code = 'grrr'; hover = false}} class:selected={code === 'grrr'}>
    <img src="/img/emoji/grrr.svg" alt="grrr emoji">
  </div>
</div>
{/if}

<div class="current-reaction">
<img class="react" class:hide={hover} src="/img/emoji/{code || 'react'}.svg" alt="react emoji" width="64px" height="64px" on:click={()=>{ hover = true}} >
<span class="code">{#if code}{code}{/if}</span>
</div>


<style>
.reactions{
  display:flex;
  width:auto;
  padding:0rem 1rem;
  margin-bottom:3rem;
  justify-content: space-evenly;
  transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
  background:#FFF;
  border-radius:4rem;
}

.current-reaction{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size:12px;
}

.react{
  margin-bottom:2rem;
  cursor:pointer;
}

.react.hide{
  display:none;
}

.react{
  display:block;
}

.code{
  text-align:center;
  position:relative;
  bottom:2rem;
}

.reaction{
  width:3rem;
  height:3rem;
  cursor:pointer;
  transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.reaction:hover{
  transform: scale(1.2);
}


.reaction:active{
  transform: scale(1);
}

.selected{
  transform: scale(1.2);
}


</style>
