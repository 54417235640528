// imports
import landingPage from './landing/landing.page.svelte'
import loginPage from './login/login.page.svelte'
import signupPage from './signup/signup.page.svelte'
import homePage from './home/home.page.svelte'
import bookedPage from './booked/booked.page.svelte'
import favoritesPage from './favorites/favorites.page.svelte'
import createdPage from './created/created.page.svelte'
import settingsPage from './settings/settings.page.svelte'
import profilePage from './profile/profile.page.svelte'
import helpPage from './help/help.page.svelte'
import privacyPage from './privacy/privacy.page.svelte'
import financePage from './finance/finance.page.svelte'
import livePage from './live/live.page.svelte'
import sessionPage from './session/session.page.svelte'
import streamingPage from './streaming/streaming.page.svelte'
import accountPage from './account/account.page.svelte'

// export
export default {
	Landing : landingPage,
	Login : loginPage,
	Signup : signupPage,
	Home : homePage,
	Booked : bookedPage,
	Favorites : favoritesPage,
	Created : createdPage,
	Settings: settingsPage,
	Profile: profilePage,
	Help: helpPage,
	Privacy: privacyPage,
	Finance: financePage,
	Live: livePage,
	Session: sessionPage,
	Streaming: streamingPage,
	Account : accountPage
}

export const Landing = landingPage
export const Login = loginPage
export const Signup = signupPage
export const Home = homePage
export const Favorites = favoritesPage
export const Booked = bookedPage
export const Created = createdPage
export const Settings = settingsPage
export const Profile = profilePage
export const Help = helpPage
export const Privacy = privacyPage
export const Finance = financePage
export const Live = livePage
export const Session = sessionPage
export const Streaming = streamingPage
export const Account = accountPage