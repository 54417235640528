<script>
	// Imports
	import { formatDate } from "../../helpers/"
	import { Module, Webnav, Aside, MobileTitle, MobileFooter, IconButton } from "../../components/";

	import Live from './live.view.svelte'

	// State
	let view = "sessions"
	let state = {}
	

</script>

<Module>

	<section slot="webnav">
		<Webnav></Webnav>
	</section>

	<section slot="mobnav">
	{#if state.live}
		<MobileTitle editable={state.isOwner} bind:title="{state.live.name}" align="flex-start" full>
		</MobileTitle>
	{/if}
	
	</section>

	<section slot="aside">
		<Aside></Aside>
	</section>
	
	<section slot="header">
		Live
	</section>

	<section slot="main" >
		<Live bind:state></Live>
  	</section>

	<section slot="footer" >
		<MobileFooter></MobileFooter>		
  	</section>
</Module>


<style>
section[slot="header"]{
	font-size: 2em;
	font-weight:600;
	padding-top:1em;
}

section[slot="mobnav"]{
	height:100%;
}

section[slot="webnav"]{
	width: 100%;
}

</style>