<script>
import { push, location } from "svelte-spa-router";
import { fetchIconPath } from './../../helpers/'


</script>

<style>

    nav{
        display:flex;
        width:100%;
        height:4em;
        box-sizing: border-box;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        padding:0em 2em;
    }


</style>
        <nav>
            <!--img src={fetchIconPath('home')} alt="Home" width="24rem" on:click={()=>{push('/home')}}-->

            <!-- <img class:selected={$location === '/home'} src="/img/house.svg" alt="house" on:click={()=>{push('/home')}}> -->
            <svg width="33" height="33" viewBox="0 0 33 33" fill="{$location === '/home' ? '#D14177' : '#868686' }" xmlns="http://www.w3.org/2000/svg" on:click={()=>{push('/home')}}>

              <!-- stroke="{$location === '/home' ? '#D14177' : '#868686' }" -->
              <g  stroke="{$location === '/home' ? '#D14177' : '#868686' }"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                   transform="matrix(0.026211, 0, 0, -0.024496, -0.130763, 24.390051)">
                <path d="M240 951 c-86 -28 -168 -98 -211 -181 -21 -39 -24 -59 -24 -150 0
            -95 3 -110 27 -155 32 -60 97 -124 158 -156 39 -21 59 -24 150 -24 91 0 111 3
            150 24 25 13 52 29 60 37 14 12 39 -10 195 -166 170 -170 215 -204 215 -162 0
            9 -81 98 -180 197 -156 156 -178 181 -166 195 8 8 24 35 37 60 21 39 24 59 24
            150 0 121 -15 162 -89 238 -67 70 -125 95 -231 99 -49 1 -101 -1 -115 -6z
            m227 -67 c223 -106 221 -420 -4 -530 -189 -93 -416 52 -416 266 0 218 222 357
            420 264z"></path>


                <!-- path d="M19.3727 26.1525V20.1524C19.3727 19.8872 19.2673 19.6328 19.0798 19.4453C18.8922 19.2577 18.6379 19.1524 18.3727 19.1524H14.3727C14.1074 19.1524 13.8531 19.2577 13.6656 19.4453C13.478 19.6328 13.3727 19.8872 13.3727 20.1524V26.1525C13.3727 26.4177 13.2673 26.672 13.0798 26.8596C12.8923 27.0471 12.638 27.1525 12.3728 27.1525L6.37352 27.1533C6.24219 27.1533 6.11214 27.1275 5.9908 27.0772C5.86946 27.027 5.75921 26.9533 5.66634 26.8605C5.57346 26.7676 5.49979 26.6574 5.44953 26.536C5.39927 26.4147 5.3734 26.2846 5.3734 26.1533V14.5958C5.3734 14.4564 5.40251 14.3187 5.45887 14.1912C5.51522 14.0638 5.59758 13.9496 5.70066 13.8559L15.7 4.76402C15.884 4.59666 16.1239 4.50391 16.3727 4.50391C16.6215 4.5039 16.8613 4.59663 17.0454 4.76398L27.0461 13.8559C27.1492 13.9496 27.2316 14.0638 27.2879 14.1912C27.3443 14.3187 27.3734 14.4565 27.3734 14.5958V26.1533C27.3734 26.2846 27.3475 26.4147 27.2973 26.536C27.247 26.6574 27.1733 26.7676 27.0805 26.8605C26.9876 26.9533 26.8773 27.027 26.756 27.0772C26.6347 27.1275 26.5046 27.1533 26.3733 27.1533L20.3725 27.1525C20.1073 27.1525 19.853 27.0471 19.6655 26.8596C19.478 26.672 19.3727 26.4177 19.3727 26.1525V26.1525Z"  stroke="{$location === '/home' ? '#D14177' : '#868686' }"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" / -->
            </svg>

            <svg width="33" height="33" viewBox="0 0 33 33" fill="{$location === '/booked' ? '#D14177' : '#868686' }" xmlns="http://www.w3.org/2000/svg" on:click={()=>{push('/booked')}}>
            <g  stroke="{$location === '/booked' ? '#D14177' : '#868686' }"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
                transform="matrix(0.026211, 0, 0, -0.024496, -0.130763, 24.390051)">
                <path d="M200 920 l0 -40 -88 0 c-55 0 -92 -4 -100 -12 -17 -17 -17 -839 0
                -856 17 -17 919 -17 936 0 17 17 17 839 0 856 -8 8 -45 12 -100 12 l-88 0 0
                40 c0 33 -3 40 -20 40 -17 0 -20 -7 -20 -40 l0 -40 -240 0 -240 0 0 40 c0 33
                -3 40 -20 40 -17 0 -20 -7 -20 -40z m0 -140 c0 -53 2 -60 20 -60 18 0 20 7 20
                60 l0 60 240 0 240 0 0 -60 c0 -53 2 -60 20 -60 18 0 20 7 20 60 l0 60 68 0
                c40 0 73 -5 80 -12 7 -7 12 -40 12 -80 l0 -68 -440 0 -440 0 0 68 c0 40 5 73
                12 80 7 7 40 12 80 12 l68 0 0 -60z m720 -428 c0 -211 -3 -291 -12 -300 -17
                -17 -839 -17 -856 0 -9 9 -12 89 -12 300 l0 288 440 0 440 0 0 -288z"/>
                <path d="M278 509 c-67 -55 -40 -96 29 -43 l33 25 0 -135 0 -136 -50 0 c-43 0
                -50 -3 -50 -20 0 -19 7 -20 120 -20 113 0 120 1 120 20 0 17 -7 20 -50 20
                l-49 0 -3 158 -3 157 -30 2 c-21 2 -41 -6 -67 -28z"/>
                <path d="M527 534 c-4 -4 -7 -27 -7 -51 0 -36 3 -43 20 -43 16 0 20 7 20 30
                l0 30 75 0 c41 0 75 -3 75 -6 0 -3 -25 -66 -55 -140 -61 -151 -66 -177 -34
                -172 17 2 33 31 81 152 33 84 58 160 56 175 l-3 26 -111 3 c-60 1 -113 -1
                -117 -4z"/>
                <!-- path d="M25.8919 15.2987L7.89486 4.30017C7.74327 4.20753 7.56974 4.15694 7.39212 4.15362C7.2145 4.15029 7.0392 4.19434 6.88425 4.28124C6.7293 4.36814 6.6003 4.49475 6.51051 4.64805C6.42073 4.80134 6.3734 4.97579 6.3734 5.15344V27.1506C6.3734 27.3283 6.42073 27.5027 6.51051 27.656C6.6003 27.8093 6.7293 27.9359 6.88425 28.0228C7.0392 28.1097 7.2145 28.1538 7.39212 28.1504C7.56974 28.1471 7.74327 28.0965 7.89486 28.0039L25.8919 17.0053C26.0381 16.916 26.1589 16.7906 26.2427 16.6412C26.3265 16.4918 26.3705 16.3233 26.3705 16.152C26.3705 15.9807 26.3265 15.8123 26.2427 15.6629C26.1589 15.5135 26.0381 15.3881 25.8919 15.2987Z" stroke="{$location === '/booked' ? '#D14177' : '#868686' }"   stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/-->
            </svg>


            <svg width="33" height="33" viewBox="0 0 33 33" fill="{$location === '/favorites' ? '#D14177' : '#868686' }" xmlns="http://www.w3.org/2000/svg" on:click={()=>{push('/favorites')}}>

            <g  stroke="{$location === '/favorites' ? '#D14177' : '#868686' }"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
                transform="matrix(0.026211, 0, 0, -0.024496, -0.130763, 24.390051)">

                <path d="M567 931 c-13 -16 -55 -79 -93 -140 l-69 -110 -65 -1 c-93 -1 -169
                -33 -193 -82 -26 -53 -14 -80 71 -170 l76 -79 -147 -147 c-118 -118 -147 -153
                -147 -175 0 -23 4 -27 27 -27 22 0 57 29 175 147 l147 147 79 -76 c90 -85 117
                -97 170 -71 49 24 81 100 82 193 l1 65 110 69 c160 100 169 108 169 164 0 46
                0 47 -138 184 -137 138 -138 138 -184 138 -39 0 -50 -5 -71 -29z m207 -160
                c78 -78 117 -124 114 -136 -1 -9 -64 -56 -138 -104 l-135 -87 -1 -59 c-1 -84
                -11 -129 -36 -160 l-21 -27 -178 178 c-99 99 -175 183 -172 190 9 24 86 46
                161 45 l73 0 63 97 c35 53 75 116 90 140 14 23 34 42 44 42 9 0 71 -53 136
                -119z"/>
                <!--path d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55l-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z" stroke="{$location === '/favorites' ? '#D14177' : '#868686' }"   stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/-->
            </svg>

            <svg width="33" height="33" viewBox="0 0 33 33" fill="{$location === '/created' ? '#D14177' : '#868686' }" xmlns="http://www.w3.org/2000/svg"  on:click={()=>{push('/created')}}>
              <g  stroke="{$location === '/created' ? '#D14177' : '#868686' }"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
                transform="matrix(0.026211, 0, 0, -0.024496, -0.130763, 24.390051)">
                <path d="M393 627 l-334 -332 -29 -125 c-16 -69 -30 -135 -30 -147 0 -30 15
                -30 169 6 l124 29 334 334 333 334 0 49 c0 47 -3 51 -68 117 -66 65 -70 68
                -117 68 l-49 0 -333 -333z m467 228 c53 -54 67 -92 41 -118 -8 -8 -31 9 -91
                68 -44 44 -80 83 -80 88 0 11 41 25 61 20 9 -3 40 -29 69 -58z m-86 -84 c50
                -50 76 -83 71 -91 -5 -8 -35 15 -91 71 -73 73 -91 99 -66 99 4 0 43 -36 86
                -79z m-59 -61 l80 -80 -253 -253 -252 -252 -82 83 -83 82 250 250 c137 138
                252 250 255 250 3 0 41 -36 85 -80z m-483 -611 c-4 -4 -46 -15 -93 -26 l-86
                -20 19 81 c10 45 21 87 24 95 3 10 26 -6 74 -54 38 -38 66 -72 62 -76z"/>
                <!-- path d="M6.37341 24.1534L26.3734 24.1534C27.478 24.1534 28.3734 23.258 28.3734 22.1534L28.3734 8.15344C28.3734 7.04887 27.478 6.15344 26.3734 6.15344L6.37341 6.15344C5.26884 6.15344 4.37341 7.04887 4.37341 8.15344L4.37341 22.1534C4.37341 23.258 5.26884 24.1534 6.37341 24.1534Z" stroke="{$location === '/created' ? '#D14177' : '#868686' }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M20.3734 28.1534H12.3734" stroke="{$location === '/created' ? '#D14177' : '#868686' }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/ -->
            </svg>
        </nav>
