import API from './api.js'
import qs from 'qs';

        const createService = async (body)=>{
            return await API.post(`availabilities`, body)
        }
            const findOneService = async (ref_uuid, query)=>{
            const queryString = qs.stringify(query)
            return await API.get(`availabilities/${ ref_uuid }?${queryString}`)
        }
        const decreaseService = async (ref_uuid, body)=>{
            return await API.patch(`availabilities/${ ref_uuid }`, body)
        }
        const increaseService = async (ref_uuid, body)=>{
            return await API.patch(`availabilities/${ ref_uuid }`, body)
        }

export default  {
    create : createService,
    findOne : findOneService,
    decrease : decreaseService,
    increase : increaseService
}

export const create = createService
export const findOne = findOneService
export const decrease = decreaseService
export const increase = increaseService
