export default (detail)=>{
    const hasSession = localStorage.getItem('session') ? true : false ;
    if(!hasSession){

      var fake_session = {
        "id_token" : "ewogICJub25jZSI6ICJNRFk0T1dNeE16Y3RZelZqTlMwMFpERTVMV1l5Wm1ZdE5HSmpOemhqTlRCbFl6STAiLAogICJ0aW1lc3RhbXAiOiAiMjAxNy0xMi0xM1QxNTozODozMi4wMDBaIiwKICAidXNlcl91dWlkIjogIjM3MTFlNGU3LWY1NzQtNGU3MC05N2FjLWUzMjA0MjJiZDBhOCIKfQ==",
        "user" : {
          "uuid" : "3711e4e7-f574-4e70-97ac-e320422bd0a8"
        }
      };
      var fake_session_str = JSON.stringify(fake_session)
      console.log(fake_session_str);
      localStorage.setItem('session',fake_session_str);
      return true
    }


    if(hasSession && detail.location == '/login'){
        return false
    }
    if(!hasSession && detail.location == '/login'){
        return true
    }
    return hasSession
}
