<script>
	export let icon = "settings"
	export let label
	export let size = "2.5em"
  export let backgroundColor = "#F0F0F0";
  export let foregroundColor = "var(--theme-dark-gray)";
	import { fetchIconPath } from './../../helpers/'



  let style = `
      --size:${size};
      --backgroundColor:${backgroundColor};
      --foregroundColor:${foregroundColor};
  `

</script>

<span class="iconButton-holder" on:click {style}>
	<div class="icon-holder">
        <slot>
            <img src={fetchIconPath(icon)} alt="{icon}" width="100%" height="100%">
        </slot>
	</div>
    {#if label}
	<p>{label}</p>
    {/if}
</span>

<style>
 	.icon-holder{
        border-radius:50%;
        width: var(--size);
        height: var(--size);
        overflow:hidden;
        box-sizing: border-box;
        padding:0.5rem;
        text-align:center;
        background:var(--backgroundColor);
        text-align:center;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        cursor:pointer;
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .iconButton-holder{
        display:flex;
        flex-direction:column;
        width:var(--size);
        align-items: center;
        justify-content: center;
        justify-items: center;
        text-align: center;
        margin-left:1rem;
    }
    .iconButton-holder p{
        font-size:0.7rem;
        /* color:var(--theme-dark-gray) */
        color:var(--foregroundColor);
    }


	.icon-holder:hover{
		filter: brightness(0.95);
	}

	.icon-holder:active{
		filter: brightness(0.8);
	}
</style>
