import API from './api.js'
import { confirmPassword, forgotPassword, signIn } from './auth.service.js'
import Office_bookings from './Office.bookings.js'
import Bank_packs from './Bank.packs.js'
import Bank_tokens from './Bank.tokens.js'
import Favorite_favorites from './Favorite.favorites.js'
import Feedback_feedbacks from './Feedback.feedbacks.js'
import Catalogue_categories from './Catalogue.categories.js'
import CatalogueManager_sessions from './CatalogueManager.sessions.js'
import CatalogueManager_lives from './CatalogueManager.lives.js'
import CatalogueManager_shows from './CatalogueManager.shows.js'
import CatalogueImage_images from './CatalogueImage.images.js'
import CatalogueAvailability_availabilities from './CatalogueAvailability.availabilities.js'
import Streaming_streamings from './Streaming.streamings.js'
import Setting_settings from './Setting.settings.js'

export default {
    instance : API.instance,
    auth : {
        confirmPassword,
        forgotPassword,
        signIn
    },
    Office : {
        bookings : Office_bookings
    },
    Bank : {
        packs : Bank_packs,
        tokens : Bank_tokens
    },
    Favorite : {
        favorites : Favorite_favorites
    },
    Feedback : {
        feedbacks : Feedback_feedbacks
    },
    Catalogue : {
        categories : Catalogue_categories
    },
    CatalogueManager : {
        sessions : CatalogueManager_sessions,
        lives : CatalogueManager_lives,
        shows : CatalogueManager_shows
    },
    CatalogueImage : {
        images : CatalogueImage_images
    },
    CatalogueAvailability : {
        availabilities : CatalogueAvailability_availabilities
    },
    Streaming : {
        streamings : Streaming_streamings
    },
    Setting : {
        settings : Setting_settings
    }
}