<script>

import { onMount, getContext  } from 'svelte'
import { fly } from 'svelte/transition';
import { location, push } from 'svelte-spa-router'
import { Button, Session, Hashtags, Input, Group, Image } from "../../components/";
import API from '../../services/client'

import { default as Dropzone } from 'svelte-file-dropzone'

import { text_area_resize } from '../../helpers/autoresize_textarea.js'


import fetchSession from './fetchSession'
import saveSession from './saveSession'

import createLive from './createLive'


const { session } = getContext('services')
const { getHashtags } = getContext('helpers')

export let state = {
	session_uuid : '',
	imageUploading : false,
	image : null,
	imagePreview : null,
	loading : null,
	session : {
		tokens : 10
	}
}

let sessionLoader = async()=>{
	state.session_uuid = $location.split('/')[2]
	state.session = {...await fetchSession(state.session_uuid)}
	return {...state.session}
};

let handleSelect = (e, session)=> {
	const { acceptedFiles, fileRejections } = e.detail;
	state.image = acceptedFiles[0]

	var reader = new FileReader();

	reader.onload = function (ev) {
		state.imagePreview = ev.target.result
		handleUpload(session)
	};

	reader.readAsDataURL(state.image);
 }

 const handleUpload = async (session)=> {
		state.imageUploading = true;
		let formData = new FormData();
		formData.append('upfile', state.image);
		formData.append('session_uuid', state.session_uuid);
		const image = await API.CatalogueImage.images.create(formData);
		state.image = null;
		state.imageUploading = false;
		session.images.push({
			href : state.imagePreview,
			uuid : new Date().toTimeString()
		})
		const tmp = [...session.images]
		session.images = tmp
		state.session = session
		state.imagePreview = null;
}

const handleUpdate = async ()=>{
	const data = {
		description : state.session.description,
		category : state.session.category,
		tokens : state.session.tokens,
		language_iso : state.session.language_iso,
		hashtags : state.session.hashtags,
		name : state.session.name
	}
	const updatedSession = await saveSession(state.session.uuid, data)
}

const handleRemove = async ()=>{
	API.CatalogueManager.sessions.remove(state.session_uuid)
	push('/created/session')
}

const handleAddLive = async()=>{
	var live_body = {
		"description": "Live description",
		from : new Date().toISOString(),
		to : new Date().toISOString(),
		"language":"en",
		"name": "New live",
		"session_uuid": state.session.uuid
	}
	const newLive = await createLive(live_body);
	push(`/live/${newLive.live_uuid}`)
	//push(`/live/new-${state.session.uuid}`)

}

onMount(async ()=>{
	state.loading = sessionLoader()
	state.userSession = session.getSession().user
	state.loading.then((results)=>{
		state.session = results
		state.session.hashtags_text = state.session.hashtags.join(" ")
		state.isOwner = state.userSession.uuid === state.session.creator_uuid
	})


})

</script>


{#await state.loading}
Loading
{:then session}
{#if session}

<div transition:fly="{{ y: 100, duration: 500 }}" class="session-container">
	<Input disabled={!state.isOwner} type="number" label="Tokens" bind:value={state.session.tokens} height="1.5em" inputWidth="1.5em" align="center"></Input>

	{#if state.isOwner}
		<input type="text" bind:value={state.session.hashtags_text} on:change={()=>{state.session.hashtags = getHashtags(state.session.hashtags_text); state.session.hashtags_text = state.session.hashtags.join(" ") }}/>
		<textarea bind:value={state.session.description} use:text_area_resize/>
	{:else}
	<Hashtags hashtags={session.hashtags}></Hashtags>
	<p>{session.description}</p>
	{/if}



	<Group wrap justifyContent="center">
		{#each session.images as img, i}
			<Image margin="0em 1.5rem 1.5rem 0em" width="calc(50% - 1.5rem)" src="{img.href}"></Image>
		{/each}
		<!-- {#if !state.imageUploading} -->
		{#if state.isOwner}
		<Dropzone multiple={false}  on:drop={(e)=>{handleSelect(e, session)}} containerStyles="margin:4rem 4rem 4rem 4rem;border-radius:1rem;">
			{#if state.imageUploading}
			<img src="{state.imagePreview}" alt="icon preview" width="48px">
			{:else}
			Drag and drop a valid image file here
			{/if}
		</Dropzone>
		{/if}
		<!-- {:else} -->
		<!-- <div class="progress-line"></div> -->
		<!-- {/if} -->
	</Group>

	{#if state.isOwner}
		<div class="actions-container">
			<Button width="calc(50% - 1em)" style="margin:1px" on:click={handleUpdate} rounded>Save</Button>
			<Button width="calc(50% - 1em)" style="margin:1px" on:click={handleRemove} rounded  backgroundColor="#C4C4C4" >Remove</Button>
			<!-- <Button width="calc(50% - 1em)" style="margin:0px" backgroundColor="#C4C4C4" rounded>Edit</Button> -->
			<!-- <Button width="calc(50% - 1em)" style="margin:0px" backgroundColor="#C4C4C4"  rounded>Cancel</Button> -->
		</div>
		<div class="actions-container">
			<Button width="98%" style="margin:2px" rounded on:click={handleAddLive}>Add live</Button>
		</div>
	{/if}
</div>
{/if}
{:catch error}
	An error ocurred while fetching the Session: {error}
{/await}

<style>
.session-container{
	display:flex;
	flex-direction: column;
	justify-content: space-between;
}

h2{
	margin-top:2rem;
	color: black;
	font-size: 1.25rem;
	font-weight:800;
}

p, textarea{
	font-weight:400;
	color:black;
	font-size:1rem;
	line-height:2rem;
	text-align: justify;
  	text-justify: inter-word;
	  font-family: 'Poppins', sans-serif;

}

input{
	  color: #2A50B2;
	  border:none;
	  font-size:1rem;
}

textarea{
	padding:1rem 1rem 0.1rem 1rem;
	width:98%;
	background-color: #F1EEED;
	border: none;
 	overflow: auto;
 	outline: none;

 	-webkit-box-shadow: none;
 	-moz-box-shadow: none;
 	box-shadow: none;

 	resize: none; /*remove the resize handle on the bottom right*/
}

textarea:focus, textarea:active, input:focus, input:active{
	outline: none;
}

.title-with-slot{
	width:100%;
	display:flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom:1rem;
}

.actions-container{
	display:flex;
	justify-content: space-between;
	margin-bottom:1rem;
}


</style>
