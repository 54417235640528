<script>
	// Imports
	import { formatDate } from "../../helpers/"
	import { Module, Webnav, Aside, Mobilenav, MobileFooter } from "../../components/";
	import { getContext, onMount } from "svelte";

	import Lives from './lives.view.svelte'
	


	// Exports
	export const page = "Dashboard" 

	// State
	const { client, session} = getContext("services")
	// let firstname = session.getSession().user.name.split(' ')[0] || '🌄'
	let records = []
	
	// Fetch 
	const fetchRecords = () => { 
	
	}

</script>

<style>
section[slot="header"]{
	font-size: 2em;
	font-weight:600;
	padding-top:1em;
}

section[slot="mobnav"]{
	height:100%;
}

section[slot="webnav"]{
	width: 100%;
}
</style>

<Module>

	<section slot="webnav">
		<Webnav></Webnav>
	</section>

	<section slot="mobnav">
		<Mobilenav></Mobilenav>
	</section>

	<section slot="aside">
		<Aside></Aside>
	</section>
	
	<section slot="header">
		Available Live
	</section>

	<section slot="main" >
		<Lives></Lives>
  	</section>

	<section slot="footer" >
		<MobileFooter></MobileFooter>		
  	</section>
</Module>


