<script>
	// Imports
	import { formatDate } from "../../helpers/"
	import { Module as Page, Webnav, Aside, MobileTitle, MobileFooter, Input, Button, Toggle, Select } from "../../components/";
	import { getContext, onMount } from "svelte";

	// State
	const { client, session} = getContext("services")
	let records = []
	let engine;
	
	// Fetch 
	const fetchRecords = () => { 
	
	}

</script>

<style>
section[slot="header"]{
	font-size: 2em;
	font-weight:600;
	padding-top:1em;
}

section[slot="mobnav"]{
	height:100%;
}

section[slot="webnav"]{
	width: 100%;
}

section[slot="main"]{
	display:flex;
	flex-direction: column;
	justify-content: space-between;
	align-items:center;
	height:100%;
}



.row{
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: space-between;
	width:24em;
	height: 4em;
}

.row p{
	width:20rem;
	margin-right:1rem;
}

.row span{
	width:auto;
	display:flex;
	justify-content: flex-start;
	width:8em;
		
}

img{
	position:fixed;
	bottom:6em;
	left:0em;
	z-index:-1;
	width:32rem;
}


.form{
	width:20rem;
	align-self:flex-start;
}

.chevron-right{
	font-weight: 600;
}

.clickable{
	cursor: pointer;
}

@media (min-width:801px) {	
	section[slot="main"]{
		justify-content: flex-start;
		align-items:flex-start;
	}
	img{
		position:fixed;
		left: 50%;
		right:8rem;
		bottom:8rem;
		width:40rem;
	}
	.form{
		width:100%;
	}


}
</style>

<Page>

	<section slot="webnav">
		<Webnav></Webnav>
	</section>

	<section slot="mobnav">
		<MobileTitle title="Settings"></MobileTitle>
	</section>

	<section slot="aside">
		<Aside></Aside>
	</section>
	
	<section slot="header">
		Settings
	</section>

	<section slot="main" >
		<div class="form">
			<div class="row">
				<p><b>Language</b></p>
				<span>
					<Select style="width:8em;" padding="0em" value="Erick" backgroundColor="white" foregroundColor="black">
						<option selected value="en">English</option>
						<option selected value="it">Italian</option>
						<option selected value="es">Spanish</option>
					</Select>
				</span>
			</div>
			<div class="row">
				<p><b>Notification</b></p>
				<span><Toggle checked></Toggle></span>
			</div>
			<div class="row clickable">
				<p><b>GDPR and Approval</b></p>
				<span class="chevron-right">{'>'}</span>
			</div>
			<div class="row clickable">
				<p><b>Contact / Help</b></p>
				<span class="chevron-right">{'>'}</span>
			</div>
		</div>
		<img src="/img/settings.png" alt="Settings graphic">
  	</section>

</Page>