import API from '../../services/client'

export default async (live_uuid)=>{
        let live = await API.CatalogueManager.lives.findOne(live_uuid)
        live.session = await API.CatalogueManager.sessions.findOne(live.session_uuid)
        live.availability = await API.CatalogueAvailability.availabilities.findOne(live.uuid)
        live.feedback = await API.Feedback.feedbacks.findMany({ live_uuid : live.uuid })
        live.images = await API.CatalogueImage.images.findMany({ session_uuid : live.session_uuid })
        try{
                live.bookings = await API.Office.bookings.findMany({ live_uuid : live.uuid })
        }catch(err){
                live.bookings = false
        }

        return live

}
