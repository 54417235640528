<script>
    import { mdiMagnify } from '@mdi/js';

    export let id = "Search"

    export let margin = "0em 0em 1em 0em";
    export let placeholder = "Search something here"

    export let value = null


</script>

<style>
    .search-container{
        height:2em;
        display:flex;
        justify-content: space-between;
        align-items: center;
        justify-items: center;
        background:#F2F2F2;
        padding:0.5em 1em;
        margin: var(--margin);
        border-radius:0.25em;
    }

    .search-container input{
        width:100%;
        margin-right:0.5em;
        border:none;
        background: #F2F2F2;

    }

    .search-container input:focus{
        outline: none;
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #BABABA;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: #BABABA;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #BABABA;
}


@media (min-width:1400px) {
    .search-container{
        max-width:480px;
    }
}

</style>

<form class="search-container" style="--margin:{margin}" on:submit on:change on:input>
    <input {placeholder} {id} bind:value={value} />
    <label for={id} style="opacity:0;">Search</label>
    <svg style="width:2em;height:2em;" viewbox="0 0 24 24">
        <path fill="#CACACA" d="{mdiMagnify}" />
    </svg>
</form>
