<script>

import { formatDate } from './../../helpers/'
import { fetchIconPath } from './../../helpers/'
import { location, push } from 'svelte-spa-router'
import { IconButton } from './../../components'




export let logo = "img/logo.svg"

export let links = [
    {
        url : "/home",
        display : "Home",
        name: "home"
    },
    {
        url : "/booked",
        display : "Booked",
        name: "booked"
    },
    {
        url : "/favorites",
        display : "Favorites",
        name: "favorites"
    },
    {
        url : "/created",
        display : "Created",
        name: "created"
    },
    {
        url : "/account",
        display : "Account",
        matches : [ "/streaming", "/account", "/profile", "/finance" ],
        name: "account"
    },
    {
        url : "/settings",
        display : "Settings",
        name: "settings"
    },
    {
        url : "/help",
        display : "Help",
        name: "help"
    },
    // {
    //     url : "/uikit",
    //     display : "UI Kit"
    // }
]

export let style = "";

</script>

<style>

    .link_display {
        max-height: 1.1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 1rem;
        font-weight: 400;
        margin-top: 1px;
        line-height: 1.1rem;
    }

    .aside-container{
        display:flex;
        /* grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-template-areas: "nav"; */
        flex-direction: column;
        justify-content: flex-start;
        width:100%;
        align-content: center;
        align-items: flex-start;
        box-sizing: border-box;
        padding: 0em 0em 0em 0em;
    }

    /* .logo-holder{
        grid-area:logo;
        display:flex;
        justify-content:center;
        align-content:center;
        align-items:center;
    } */


    ul{
          list-style-type: none;
          width:100%;
          height:100%;
          padding:0em;
          grid-area: nav;
          display:flex;
          flex-direction:column;
          align-items:flex-start;
          align-content:flex-start;
    }

    ul li{
        height:auto;
        text-align:left;
        margin-bottom:2em;
        box-sizing: border-box;
        cursor:pointer;
        width: 100%;
    }

    ul li.selected{
        border-bottom: solid 4px var(--flio-primary);
    }

    p{
        color: var(--flio-black);
        margin-bottom:0.5rem;
        /* margin-left:1.5em; */
        text-align:center;
        font-size:20px;
        font-weight:500;
    }

    @media (min-width:1281px) {
        /* .aside-container{
            padding: 0em 8em;
        } */
    }
</style>


    <div class="aside-container" {style}>


        <ul>
            {#each links as link, i}
            <li class:selected={link.matches ? link.matches.indexOf($location) > -1 : $location === link.url} on:click={()=>{ push(`#${link.url}`) }}>
                <center>
                <!-- IconButton label="{link.display}" icon="{link.name}"></IconButton -->

                  <img src={fetchIconPath(link.name)} alt={link.display} width="24rem">
                  <div class="link_display">{link.display}</div>
                </center>
            </li>
            {/each}

        </ul>


    </div>
