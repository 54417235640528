

const setPrimaryColorService = (color = '#D34075')=>{
    document.documentElement.style.setProperty('--flio-primary', color);
}

export default {
    setPrimaryColor : setPrimaryColorService
}

export const setPrimaryColor = setPrimaryColorService;