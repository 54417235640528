import API from './api.js'
import qs from 'qs';

        const findManyService = async (query)=>{
            const queryString = qs.stringify(query)
            return await API.get(`/packs?${queryString}`)
        }
            const checkoutPacksService = async (body)=>{
            return await API.post(`packs`, body)
        }
    
export default  {
    findMany : findManyService,
    checkoutPacks : checkoutPacksService
}

export const findMany = findManyService
export const checkoutPacks = checkoutPacksService
