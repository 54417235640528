<script>
	export let picture
    export let name
    export let size = "2.5rem"
    export let margin = "0em";
    export let fontSize = "0.7rem"
    export let color = "#000000"
    export let direction = "column"


    let nameMarginLeft = "0rem"

    if(direction === "row"){
        nameMarginLeft = "1rem"
    }

    let style = `
        --margin:${margin};
        --color:${color};
        --direction:${direction};
    `

    let nameStyle = `
        --margin-left:${nameMarginLeft};
        --color:${color};
        --fontSize:${fontSize};
    `

    let avatarStyle = `
        --width:${size};
        --height:${size};
    `


</script>

<span class="user-holder" on:click {style}>
	<!-- div class="avatar-holder" style={avatarStyle}>
		<img src="{picture}" alt="{name}'s profile picture" width="100%" height="100%">
	</div -->
    {#if name}
	<p style={nameStyle}>{name}</p>
    {/if}
</span>

<style>
 	.avatar-holder{
        border-radius:50%;
        overflow: hidden;
        width: 2.5em;
        height: 2.5em;
        box-sizing: border-box;
        text-align:center;
    }

    .avatar-holder img{
        width:100%;
        height:100%;
    }

    b{
        font-size:18px;
        cursor: pointer;
    }

    .user-holder{
        display:flex;
        flex-direction:var(--direction);
        width:auto;
        align-items: center;
        justify-content: center;
        justify-items: center;
        text-align: center;
        /* margin-left:1em; */
    }
    .user-holder p{
        font-size:var(--fontSize);
        margin-left: var(--margin-left);
        color:var(--color)
    }

    .avatar-holder:hover{
		filter: brightness(0.95);
	}

	.avatar-holder:active{
		filter: brightness(0.8);
	}
</style>
