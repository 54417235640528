<svelte:head>
<link rel="stylesheet" type="text/css" href="https://cdn.wpcc.io/lib/1.0.2/cookieconsent.min.css"/><script src="https://cdn.wpcc.io/lib/1.0.2/cookieconsent.min.js" defer></script><script>window.addEventListener("load", function(){window.wpcc.init({"border":"thin","corners":"small","colors":{"popup":{"background":"#f6f6f6","text":"#000000","border":"#555555"},"button":{"background":"#555555","text":"#ffffff"}}, "content":{"href":"/#/privacy"}})});</script>
</svelte:head>

<script>
import { Landing, Webnav } from '../../components'

import { push } from "svelte-spa-router";
    
</script>

<Landing>

	<section slot="nav">
		<Webnav session={false}></Webnav>
	</section>

	<section slot="main">
		<figure>
			<img src="img/flio-login.png"  alt="Flio login graphic">
		</figure>
		<article>
			<img src="img/logo.png"  alt="Flio logo" width="128px" height="128px">
			<h1>Lorem ipsum<br><span>Yeah Whatever!</h1>
			<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit.</p>

		</article>
  	</section>
      
</Landing>

<style>
	section[slot="nav"]{
		width:100%;
	}
	
	section[slot="main"]{
		display:flex;
		flex-direction: column;
		padding: 2em;
		box-sizing: border-box;
		height: 100%;
		justify-content: flex-start;
		align-items: center;
	}

	h1{
		font-size:2em;
	}
	h1 span{
		margin-left: 0em;
	}
	figure{
		display:none;
		width:100%;
	}
	figure img{
		width:100%;
	}
	article{
		width:100%;
	}
	article p{
		font-weight: 600;
	}
	article img{
		 height:32px;
	}

	@media (min-width:801px) {
		article img{
		 height:128px;
	}
			h1{
				font-size:4rem;
			}

		section[slot="main"]{
			display:flex;
			flex-direction: row;
			padding: 2em 4em;
			justify-content: center;
			align-items: center;
			background:none;
		}
		figure{
			display:block;
			width:50%;
		}
		article{
			width:50%;
		}
		
	}

	@media (min-width:1400px) { 
		section[slot="main"]{
			padding: 2em 12em;
		}
		h1 span{
			margin-left: 1em;
		}
	}
</style>