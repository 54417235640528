<script>

import { onMount, getContext } from 'svelte'
import { fly } from 'svelte/transition';
import { location, push } from 'svelte-spa-router'
import { FAB, Group, Button, Chip, Feedback, Live, Modal, Reactions, Rating } from "../../components/";
import API from '../../services/client'
const { session } = getContext('services')

import fetchLive from './fetchLive'
import { text_area_resize } from '../../helpers/autoresize_textarea.js'



export let state = {
	live_uuid : '',
	feedbackModal : false
}


let newFeedback = {
  comment : "",
  score : 1,
  emotion_code : null
}

const handleCreateFeedback = ()=>{
	try{
		const feedback = {
			...newFeedback,
			live_uuid : state.live_uuid,
			language_iso: "en", // Replace with user language later
		}
		console.log(feedback);
		API.Feedback.feedbacks.create(feedback)
		state.feedbackModal = false;
	}catch(err){
		newFeedback.error = "You already provided feedback for this session"
	}
}

const handleRemoveLive = ()=>{
	API.CatalogueManager.lives.remove(state.live_uuid).then(()=>{
		push('/created/live')
	})
}

const handleUpdateLive = ()=>{
	const data = {
		"from": state.live.from,
		"to": state.live.to,
		"description": state.live.description,
		"name": state.live.name,
		"session_uuid": state.live.session_uuid,
		"presenter_uuid": state.userSession.uuid
	}
	API.CatalogueManager.lives.update(state.live_uuid, state.live).then(()=>{
		/* Here we should handle availabilities update too */
	})
}

const handleCancelBooking = ()=>{
	API.Office.bookings.remove(state.live.bookings.length ? state.live.bookings[0].uuid : state.live.bookings.booking_uuid)
	state.live.bookings = false
}

const handleCreateBooking = async ()=>{
	const data = {
		"live_uuid": state.live.uuid,
		"tokens": state.live.session.tokens
	}
	try{
		state.live.bookings = await API.Office.bookings.create(data)
	}catch(err){
		alert('Live already started')
	}
}

onMount(async ()=>{
	state.userSession = session.getSession().user;

	var location_param = $location.split('/')[2]
	if (location_param.startsWith('new')) {
		var session_uuid = location_param.replace('new-','');
		var newlive = {
		    "description": "Live description",
		    "from": new Date().toISOString(),
		    "name": "New live",
		    "presenter_uuid": state.userSession.uuid,
		    "session_uuid": session_uuid,
		    "to": new Date().toISOString(),
				"availability":
				{
							"booked_slots": 0,
				      "max_slots": 100
	 	    },
				"feedback": [],
        "images": []
		};
		state.loading = Promise.resolve(newlive);
	}else{

		state.live_uuid = location_param
		newFeedback.live_uuid = location_param
	  state.loading = fetchLive(state.live_uuid)
	}
	state.loading.then((results)=>{
		state.live = results;
		state.isOwner = state.userSession.uuid === state.live.presenter_uuid;
	})
})
</script>


{#await state.loading}
Loading
{:then live}
{#if live}

<div transition:fly="{{ y: 100, duration: 500 }}">
	<!-- Live live={live} full></Live-->
	{#if state.isOwner}
		<h2>Title</h2>
		<textarea class="title" type="text" bind:value={state.live.name} use:text_area_resize/>
	{:else}
		<h1>
		{live.name}
		</h1>

	{/if}

	<h2>Description</h2>
	{#if state.isOwner}
		<textarea type="text" bind:value={state.live.description} use:text_area_resize/>
	{:else}
	<p>{live.description}</p>
	{/if}

<div class="title-with-slot">
    <h2>Availability</h2>
    <Chip backgroundColor="var(--flio-secondary)" foregroundColor="#FFF" margin="0px" width="10rem">
		{#if !state.isOwner}
			{#if live.availability}{live.availability.max_slots - live.availability.booked_slots}{/if}
		{:else}
			{#if live.availability}<input class="slots-input" type="number" bind:value={state.live.availability.max_slots} />{/if}
		{/if}
	</Chip>
</div>
{#if state.isOwner}
	<div class="actions-container">
		<Button width="calc(50% - 1em)" style="margin:0px" rounded on:click={handleUpdateLive}>Save</Button>
		<Button width="calc(50% - 1em)" style="margin:0px" backgroundColor="#C4C4C4" rounded on:click={handleRemoveLive}>Remove</Button>
	</div>
{:else}
	<div class="actions-container">
		{#if !live.bookings}
			<Button width="100%" style="margin:0px" rounded on:click={handleCreateBooking}>Book</Button>
		{:else}
			<Button width="100%" style="margin:0px" backgroundColor="#C4C4C4" rounded on:click={handleCancelBooking}>Cancel booking</Button>
		{/if}
	</div>
{/if}
<Button width="100%"  rounded on:click={()=>{ push(`/session/${live.session_uuid}`) }}>Go to session</Button>

<div class="title-with-slot low-light">
	<h2>Feedback from users</h2>
	{#if !state.isOwner}
		<Button rounded borderRadius="2em" style="padding:0em 2em;margin:0px;height:3rem;font-size:1rem;" on:click={()=>{ state.feedbackModal = true; newFeedback = { ...live.feedback[0] }}} >
			Leave feedback
		</Button>
	{/if}
</div>
{#each live.feedback as feedback}
	<Feedback {feedback}></Feedback>
{/each}

</div>



{/if}
{:catch error}
	An error ocurred while fetching the Live
{/await}

<Modal visible={state.feedbackModal} width="calc(80vw - 8rem)" height="auto" style="border-radius:1rem;padding:2rem;">
	<div class="leave-feedback">
		<h2>Session Feedback</h2>
		<p>Rate your experience</p>
		<Rating bind:rating={newFeedback.score}></Rating>
		<p>Leave a comment (optional)</p>
		<textarea bind:value={newFeedback.comment}></textarea>
		<p>How did you feel during the experience? (optional)</p>
		<Reactions bind:code={newFeedback.emotion_code}></Reactions>
		<div class="leave-feedback-actions">
			<Button rounded borderRadius="2em" height="4rem" width="100%" on:click={handleCreateFeedback}>Send feedback</Button>
			<Button rounded borderRadius="2em" height="4rem" width="100%" backgroundColor="#C4C4C4"  on:click={()=>{ state.feedbackModal = false }} >Cancel</Button>
		</div>
		<div class="leave-feedback-actions mobile">
			<Button rounded borderRadius="2em" height="4rem" width="calc(100% - 2rem)"  on:click={handleCreateFeedback}>Send feedback</Button>
			<Button rounded borderRadius="2em" height="4rem" width="calc(100% - 2rem)" backgroundColor="#C4C4C4"  on:click={()=>{ state.feedbackModal = false }} >Cancel</Button>
		</div>
	</div>
</Modal>

<style>

.leave-feedback{
	display:flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
}

.leave-feedback textarea{
	width:100%;
	resize: none;
	height:4rem;
	padding:1rem;
}


.leave-feedback-actions{
	display:flex;
	flex-direction: column;
	justify-content: space-between;
	width:100%;
	display:none;
}

.leave-feedback-actions.mobile{
	display:flex;
}

.leave-feedback h2, p{
	align-self:flex-start;
}

h2{
	margin-top:2rem;
	color: black;
	font-size: 1.25rem;
	font-weight:800;
}

p, textarea{
	font-weight:300;
	color:black;
	font-size:1rem;
	text-align: justify;
	text-justify: inter-word;
  font-family: 'Poppins', sans-serif;
}


textarea{
	padding:1rem 1rem 0.1rem 1rem;
	width:98%;
	background-color: #F1EEED;
	border: none;
 	overflow: auto;
 	outline: none;

 	-webkit-box-shadow: none;
 	-moz-box-shadow: none;
 	box-shadow: none;

 	resize: none; /*remove the resize handle on the bottom right*/
}

textarea:focus, textarea:active, input:focus, input:active{
	outline: none;
}

.slots-input{
	border:none;
	border-bottom:solid 1px #fff;
	width: 6rem;
	text-align: center;
	color:#fff;
	background:transparent;
}

.title{
	font-size:1.1em;
}

.title-with-slot{
	width:100%;
	display:flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom:1rem;
}

.low-light{
	margin-top: 1em;
	border-top: 1px solid #333;

}

.actions-container{
	display:flex;
	justify-content: space-between;
	margin-bottom:1rem;
}


@media (min-width:801px) {

	.leave-feedback-actions{
		flex-direction: row;
		align-items: center;
		align-content: center;
		display:flex;
	}

	.leave-feedback-actions.mobile{
		display:none;
	}

}
</style>
