<script>
    import { mdiMagnify } from '@mdi/js';

    // export let type = "text";
    export let id = "field";
    export let value;
    export let label = "";
    export let disabled;

    export let width = "auto";
    export let inputWidth = "100%";
    export let height = "auto";
    export let margin = "0em 0em 2em 0em";

    export let padding = "1.5em"
    

    export let backgroundColor = "#F2F2F2";
    export let foregroundColor = "#000000";
    export let placeholderColor = "#BABABA";

    export let style = "";

    let computedStyle =    `
        --margin:${margin};
        --width:${width};
        --inputWidth:${inputWidth};
        --height:${height};
        --backgroundColor:${backgroundColor};
        --foregroundColor:${foregroundColor};
        --placholderColor:${placeholderColor};
        --padding:${padding};
        ${style}
    `
</script>

<style>
    .field-container{
        height:auto;
        width:var(--width);
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        justify-items: flex-start;
        margin: var(--margin);
        border-radius:0.25em;   
        box-sizing: border-box;
    }

    .field-container select{
        width:var(--inputWidth);
        height:var(--height);
        padding:var(--padding);
        border:none;
        background:var(--backgroundColor);
        color:var(--foregroundColor);
        text-align: var(--align);
    }

    .field-container select:focus{
        outline: none;
    }

    select{
        margin-top:1rem;
        text-align:var(--align);
        font-size:inherit;
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--placeholderColor);
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: var(--placeholderColor);
}

::-ms-input-placeholder { /* Microsoft Edge */
 color: var(--placeholderColor);
}

</style>

<label for={id} class="field-container" style={computedStyle}>
    {label}
    <select name={id} id={id} {disabled} bind:value={value}>
        <slot></slot>
    </select>
</label>


