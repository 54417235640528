import API from '../../services/client'

export default async (options)=>{

        // return await API.Catalogue.categories.findMany({ language : 'en' });
        const categories = await API.Catalogue.categories.findMany({ language : 'en' });
        

        for(let each in categories){
                const category = categories[each]
                if(options.setColor){

                        if(category.code === 'BODY'){ category.color = "red" }
                        else if(category.code === 'MIND'){ category.color = "green" }
                        else if(category.code === 'SOUL'){ category.color = "blue" }
                }else{
                        category.color = "#DB4C69"
                }
        }
     
        return categories
}