
<script>
	// Imports
	import { Page, Webnav, Aside, MobileTitle, MobileFooter, Input, Select, Button } from "../../components/";
	import { getContext, onMount } from "svelte";
	const SERVICES = getContext('services')
	const API = SERVICES.client



	// State
	let state = {
		payment : undefined,
		tokens : 0,
		error : false,
		loading : undefined
	}


	const checkout = (pack_ref)=>{
		fetch("https://www.fleeo.app/api/v1/packs/checkout", {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': state.userSession.id_token
			},
			body: JSON.stringify({cancel_url: 'http://localhost:5000/#/finance/failure',
				success_url: 'http://localhost:5000/#/finance/success',
				pack_ref
			})
        })
        .then(function (response) {
          return response.json();
        })
        .then(function (session) {
          var stripe = state.Stripe(session.checkout_public_key)
          return stripe.redirectToCheckout({ sessionId: session.checkout_session_id });
        })
        .then(function (result) {
          // If redirectToCheckout fails due to a browser or network
          // error, you should display the localized error message to your
          // customer using error.message.
          if (result.error) {
            alert(result.error.message);
          }
        })
        .catch(function (error) {

        });
	}

	onMount(()=>{
		state.Stripe = window.Stripe
		state.userSession = SERVICES.session.getSession()
		state.loading = API.Bank.packs.findMany()
	})


</script>



<Page>

	<section slot="webnav">
		<Webnav></Webnav>
	</section>

	<section slot="mobnav">
		<MobileTitle title="Token and Finance"></MobileTitle>
	</section>

	<section slot="aside">
		<Aside></Aside>
	</section>

	<section slot="header">
		Token and Finance
	</section>

	<section slot="main" >
		<div class="form">
			<!-- Select width="100%" bind:value={state.payment} label="Method of payment">
				<option selected value="meet">Paypal</option>
				<option selected value="meet">Credit Card</option>
			</Select -->
			<Input disabled label="Tokens" value={state.tokens} height="1.5em" inputWidth="1.5em" align="center" bold></Input>
			<p><b>Recharge</b></p>
			{#await state.loading}
			Loading...
			{:then packs}
			<div class="packs-container">
			{#if Array.isArray(packs)}
				{#each packs as pack, i}
				<div class="pack" on:click={()=>{checkout(pack.prices[0].pack_ref)}}>
					<p>{pack.name} pack (?? tokens - {pack.prices[0].currency.toUpperCase()} {pack.prices[0].amount / 100}.00)</p>
					<span on:click>{'>'}</span>
				</div>
				{/each}
			{/if}
			</div>
			{:catch error}
			{error}
			{/await}
		</div>
		{#if state.error}{state.error}{/if}
		<div class="form-actions">
			<Button rounded width="100%" style="margin-right:1em;margin-bottom:1em;" backgroundColor="#C4C4C4" >OLD INVOICES</Button>
			<Button rounded width="100%" style="margin-bottom:1em;">SEND NEW INVOICE</Button>
		</div>
  	</section>
</Page>



<style>
section[slot="header"]{
	font-size: 2em;
	font-weight:600;
	padding-top:1em;
}

section[slot="mobnav"]{
	height:100%;
}

section[slot="webnav"]{
	width: 100%;
}

section[slot="main"]{
	height:calc(100vh - 18rem);
	display:flex;
	flex-direction: column;
	justify-content: space-between;
}

.form{
	width:100%;
	height:auto;
	display:flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items:flex-start;
}

.form-actions{
	align-self:center;
	display:flex;
	flex-direction: column;
	width:100%;
}

.pack{
	display:flex;
	justify-content: space-between;
	align-items:center;
	cursor:pointer;
	font-size:14px;
}

.pack span{
	margin-left:2em;
	font-weight: 600;
}

@media (min-width:801px) {
	.form-actions{
		align-self:flex-start;
		display:flex;
		flex-direction: row;
		align-items:center;
		align-content: center;
		width:38em;
	}

	section[slot="main"]{
		height:100%;
		display:flex;
		flex-direction: row;
		flex-wrap:wrap;
		justify-content: space-between;
	}
}
</style>
