<script>
import { onMount } from 'svelte'
import { Search, Session, Skeleton  } from "../../components/";
import fetchSessions from './fetchSessions'

import { fly } from 'svelte/transition';

const sessionStyle = "margin-bottom:2em;"

let state = {
    
}

let sessionsLoader

onMount(()=>{
   sessionsLoader = fetchSessions()
})

</script>


<!-- <Search></Search> -->

{#await sessionsLoader}
<div class="sessions-container">
    {#each [0,1,2,3] as skeleton, i}
    <Skeleton width="100%" height="8rem" margin="0em 0em 2em 0em"></Skeleton>
    {/each}
</div>
{:then sessions}
{#if sessions}
    <div class="sessions-container" transition:fly="{{ y: 100, duration: 500 }}">
        {#if sessions.results && sessions.results.length}
            {#each sessions.results as session, i}
                <Session style="{sessionStyle}" {session} ></Session>
            {/each}
        {/if}
    </div>
{/if}
{:catch error}
{error}
    An error ocurred while fetching the created sessions
{/await}

<style>
.sessions-container{
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
</style>